import * as d3 from "d3";

import {
    BLOCK, CENTER, FONT_SIZE_UNIT, CLICK, COLOR, COLUMN, COLUMN_REVERSE, CommentBoxPlacement, VarianceIcon,
    CommentBoxTitle, DRAG, DRAGEND, DRAGSTART, FLEX, FONT_FAMILY, HEIGHT, MOUSEOVER, NONE, PX,
    ROW, ROW_REVERSE, SOLID, TOTAL_VARIANCE_SEPARATOR, WIDTH, CommentBoxVariance, MARGIN, MARGIN_BOTTOM,
    MARGIN_RIGHT, FLEX_DIRECTION, STROKE, STROKE_DASHARRAY, STROKE_WIDTH, DIV, DataProperty, CONTEXT_MENU, BOLD,
    COMMENT_BOX_SHADOW_DEFAULT, COMMENT_BOX_MARGIN_DEFAULT, COMMENT_BOX_PADDING_DEFAULT, INFO_BOX_CONTAINER, 
    COMMENT_BOX_SETTINGS_CONTAINER, FREE_MODE_COMMENT_LIMIT
} from "../library/constants";
import { VarianceSettings } from "./../settings/varianceSettings";
import { DataPoint } from "../charting/dataPoint";
import { ViewModel } from "../settings/viewModel";
import * as uiLib from "../library/ui/showTooltip";
import { createSvgElement, drawLine, drawVarianceIcon, getBoundingBoxHtml } from "../library/drawing";
import { isAdditionalMeasure } from "../library/helpers";
import { getCalculationIndexPerComparison } from "../helpers";
import { Button, Settings, DropdownSubscriber, InlineDropdown, NumberInputSubscriber, InlineNumberInput, InlineToggleButton, ToggleButtonSubscriber, ToggleButtonData } from "@zebrabi/design-library";
import { licensing } from "@zebrabi/licensing/Licensing";

export default class InfoBoxHandler {
    settings: VarianceSettings;
    infoBoxContainer: HTMLElement;
    dragLineElement: HTMLElement;
    hostElement: HTMLElement;
    hasHighlight: boolean = false;

    public static minCommentBoxWidth: number = 0;

    constructor(setting: VarianceSettings,
        commentBoxSelector: string, commentBoxDragLineSelector: string, hostElementSelector: string) {
        this.settings = setting;
        this.infoBoxContainer = <HTMLElement>document.querySelector(commentBoxSelector);
        this.dragLineElement = <HTMLElement>document.querySelector(commentBoxDragLineSelector);
        this.hostElement = <HTMLElement>document.querySelector(hostElementSelector);
    }

    /**
     * This function will remove all the events and children from the info box target element
     * @returns new instance of cleared element
     */
    public clearCommentBox(): HTMLElement {
        this.infoBoxContainer.style.display = NONE;
        this.infoBoxContainer.style.maxWidth = null;
        this.infoBoxContainer.style.maxHeight = null;
        const clearTarget = <HTMLElement>this.infoBoxContainer.cloneNode(false);
        this.infoBoxContainer.parentNode.replaceChild(clearTarget, this.infoBoxContainer);
        this.infoBoxContainer = clearTarget;
        return this.infoBoxContainer;
    }

    /**
     * Removes all the events and children comment box drag line element
     * @returns new instance of cleared element
     */
    public clearCommentsBoxDragLine(): HTMLElement {
        this.dragLineElement.style.display = NONE;
        const clearTarget = <HTMLElement>this.dragLineElement.cloneNode(false);
        this.dragLineElement.parentNode.replaceChild(clearTarget, this.dragLineElement);
        this.dragLineElement = clearTarget;
        this.dragLineElement.addEventListener(CONTEXT_MENU, e => e.preventDefault());
        return this.dragLineElement;
    }

    private setCommentBoxBorderRadius() {

        this.infoBoxContainer.style.borderRadius = null;
        if (this.settings.commentBoxBorderRadius !== 0) {
            this.infoBoxContainer.style.borderRadius = `${this.settings.commentBoxBorderRadius}px`;
        }
    }

    private setCommentBoxShadow() {
        this.infoBoxContainer.classList.remove("shadowed");
        this.infoBoxContainer.style.margin = null;
        if (this.settings.commentBoxShadow) {
            this.infoBoxContainer.classList.add("shadowed");
            this.infoBoxContainer.style.margin = "5px";
        }
    }

    private setCommentBoxBorder() {
        this.infoBoxContainer.style.border = null;
        let borderColor = this.settings.commentBoxBorderColor;
        let borderWidth = this.settings.commentBoxBorderWidth;
        this.infoBoxContainer.style.border = `${borderWidth}${PX} ${SOLID} ${borderColor}`;
    }

    private setScrollBarToLeft(commentBoxDiv: HTMLElement) {
        this.infoBoxContainer.style.direction = null;
        commentBoxDiv.style.direction = null;
        if (this.settings.commentBoxPlacement === CommentBoxPlacement.Left) {
            this.infoBoxContainer.style.direction = "rtl";
            commentBoxDiv.style.direction = "ltr";
        }
    }

    public removeCommentBoxSettings(close?: boolean): void {
        const chartCommentSettings:HTMLElement = document.querySelector(".comment-settings");
        const commentSettingsContainer :HTMLElement = document.querySelector(".settings-interactive-container");
        if (chartCommentSettings) {
            if (commentSettingsContainer) {
                commentSettingsContainer.parentNode.removeChild(commentSettingsContainer);
            }
            if (close) {
                chartCommentSettings.classList.remove("open");
            }
        }
    }

    public hideCommentBoxSettings(): void {
        const tableCommentSettings: HTMLDivElement = document.querySelector(`.${COMMENT_BOX_SETTINGS_CONTAINER}`);

        if (!tableCommentSettings) {
            return;
        }

        const commentSettingsContainer: HTMLDivElement = document.querySelector('.settings-interactive-container');

        if (commentSettingsContainer) {
            commentSettingsContainer.parentNode.removeChild(commentSettingsContainer);
        }
    }

    private createCommentBoxSettings(): HTMLDivElement {
        let tableCommentSettings: HTMLDivElement = document.querySelector(`.${COMMENT_BOX_SETTINGS_CONTAINER}`);

        if (!tableCommentSettings) {
            tableCommentSettings = d3.select('.visual')
                .append('div')
                .classed(COMMENT_BOX_SETTINGS_CONTAINER, true)
                .node();
        }

        document.querySelector(".comment-box").classList.add("interactive");

        return tableCommentSettings;
    }

    private drawCommentBoxSettings(commentBoxMarginTop:number) {
        this.hideCommentBoxSettings();
        const tableCommentSettings = this.createCommentBoxSettings();

        new Settings().renderSettingsContainer(`.${COMMENT_BOX_SETTINGS_CONTAINER}`);

        const settingsArrow: HTMLElement = document.querySelector('.settings-arrow');
        settingsArrow.style.position = 'initial';

        const iconSettings: HTMLElement = document.querySelector('.icon-settings');
        iconSettings.style.position = 'initial';

        const settingsItems: HTMLElement = document.querySelector('.settings-items');
        settingsItems.style.cursor = 'default';
        settingsItems.style.zIndex = '101';

        const title: HTMLElement = settingsItems.querySelector('.title-settings');
        title.innerText = 'Comment';

        //Title
        this.addTitleOptionsDropdown(settingsItems);

        // Variance
        this.addVarianceOptionsDropdown(settingsItems);

        // Variance icon
        this.addVarianceIconOptionsDropdown(settingsItems);

        // Padding
        this.addPaddingNumberInput(settingsItems);

        // Gap
        this.addGapNumberInput(settingsItems);

        // Shadow
        this.addShadowToggleButton(settingsItems);

        const resetButton: HTMLElement = new Button('Reset to default').renderButton();
        resetButton.style.marginTop = "10px";
        settingsItems.appendChild(resetButton);

        resetButton.addEventListener(CLICK, () => {
            this.settings.commentBoxShadow = COMMENT_BOX_SHADOW_DEFAULT;
            this.settings.commentBoxItemsMargin = COMMENT_BOX_MARGIN_DEFAULT;
            this.settings.commentBoxPadding = COMMENT_BOX_PADDING_DEFAULT;
            this.settings.commentBoxTitle = CommentBoxTitle.TitleValueVariance;
            this.settings.commentBoxVarianceIcon = VarianceIcon.Triangle;
            this.settings.commentBoxShowVariance = CommentBoxVariance.RelativeVariance;
            this.settings.persistCommentBoxSettings();
        });

        const round = (number: number) => Math.round(number);

        window.requestAnimationFrame(() => {
            const commentBoundingBox = document.querySelector(`.${INFO_BOX_CONTAINER}`).getBoundingClientRect();
            const tableContainerBoundingBox = this.hostElement.getBoundingClientRect();
            const settingsAvailableHeight = tableContainerBoundingBox.bottom - commentBoundingBox.top - 20;
            const defaultSettingsHeight = 240;
            settingsItems.style.height = `${Math.min(defaultSettingsHeight, settingsAvailableHeight)}${PX}`;
            tableCommentSettings.style.top = `${round(commentBoundingBox.top + commentBoxMarginTop )}px`;
            tableCommentSettings.style.left = `${round(commentBoundingBox.left + 2)}px`;

            // TODO this is a temporary fix until design-library fixes the positioning issue ZCH-3775
            settingsItems.style.left = this.settings.commentBoxPlacement === CommentBoxPlacement.Right
                ? '-230px'
                : '32px';
        });
    }

    private addShadowToggleButton(settingsItems: HTMLElement) {
        const shadowToggle = new InlineToggleButton(this.settings.commentBoxShadow, {
            name: "comment-box-shadow",
            label: "Shadow"
        });
        shadowToggle.appendTo(settingsItems);
        const shadowSub = new ToggleButtonSubscriber();
        shadowSub.update = (message: ToggleButtonData): void => {
            this.settings.commentBoxShadow = message.value;
            this.settings.persistCommentBoxSettings();
        };
        shadowToggle.subscribe(shadowSub);
    }

    private addGapNumberInput(settingsItems: HTMLElement) {
        const gapInput = new InlineNumberInput("Gap between comments", this.settings.commentBoxItemsMargin, {
            name: "coment-box-padding",
            min: 0,
            max: 50,
        });
        gapInput.appendTo(settingsItems);
        const gapSub = new NumberInputSubscriber();
        gapSub.update = (value: number) => {
            this.settings.commentBoxItemsMargin = value;
            this.settings.persistCommentBoxSettings();
        };
        gapInput.subscribe(gapSub);
    }

    private addPaddingNumberInput(settingsItems: HTMLElement) {
        const paddingInput = new InlineNumberInput("Padding", this.settings.commentBoxPadding, {
            name: "coment-box-padding",
            min: 0,
            max: 50,
        });
        paddingInput.appendTo(settingsItems);

        const paddingSub = new NumberInputSubscriber();
        paddingSub.update = (message: number) => {
            this.settings.commentBoxPadding = message;
            this.settings.persistCommentBoxSettings();
        };
        paddingInput.subscribe(paddingSub);
    }

    private addVarianceIconOptionsDropdown(settingsItems: HTMLElement) {
        const varianceIconDropdown = new InlineDropdown<VarianceIcon>("Variance icon", this.settings.commentBoxVarianceIcon, {
            name: "comment-box-variance-icon"
        }, [
            { label: "Circle", value: VarianceIcon.Circle },
            { label: "Circle and arrow", value: VarianceIcon.CircleWithArrow },
            { label: "Triangle", value: VarianceIcon.Triangle },
        ]);
        varianceIconDropdown.appendTo(settingsItems);
        const varianceIconSub = new DropdownSubscriber<VarianceIcon>();
        varianceIconSub.update = (value: VarianceIcon): void => {
            this.settings.commentBoxVarianceIcon = value;
            this.settings.persistCommentBoxSettings();
        };
        varianceIconDropdown.subscribe(varianceIconSub);
    }

    private addVarianceOptionsDropdown(settingsItems: HTMLElement) {
        const varianceDropdown = new InlineDropdown<CommentBoxVariance>("Variances", this.settings.commentBoxShowVariance, {
            name: "comment-box-variances"
        }, [
            { label: "Absolute variance", value: CommentBoxVariance.AbsoluteVariance },
            { label: "Relative variance", value: CommentBoxVariance.RelativeVariance },
            { label: "Absolute and relative", value: CommentBoxVariance.AbsoluteAndRelativeVariance }
        ]);
        varianceDropdown.appendTo(settingsItems);

        const varianceSub = new DropdownSubscriber<CommentBoxVariance>();
        varianceSub.update = (value: CommentBoxVariance): void => {
            this.settings.commentBoxShowVariance = value;
            this.settings.persistCommentBoxSettings();
        };
        varianceDropdown.subscribe(varianceSub);
    }

    private addTitleOptionsDropdown(settingsItems: HTMLElement) {
        const titleDropdown = new InlineDropdown<CommentBoxTitle>("Title", this.settings.commentBoxTitle, {
            name: "comment-box-title"
        }, [
            { label: "Off", value: CommentBoxTitle.Off },
            { label: "Title", value: CommentBoxTitle.Title },
            { label: "Title, value", value: CommentBoxTitle.TitleValue },
            { label: "Title, value, variances", value: CommentBoxTitle.TitleValueVariance },
            { label: "Title, variances", value: CommentBoxTitle.TitleVariance },
        ]);
        titleDropdown.appendTo(settingsItems);

        const titleSub = new DropdownSubscriber<CommentBoxTitle>();
        titleSub.update = (value: CommentBoxTitle): void => {
            this.settings.commentBoxTitle = value;
            this.settings.persistCommentBoxSettings();
        };
        titleDropdown.subscribe(titleSub);
    }

    /**
 * This function will create the containers and populate it with the comments available in the chartDate
 * @param viewModel - view model containing the dataPoints
 * @param bottomMargin - determins the bottom margin of the comment container box
 * @param topMargin  - determins the top margin of the comment container box
 */
    public drawCommentBox(viewModel: ViewModel, bottomMargin: number, topMargin: number, axisBreak: number = 0) {
        const dataPoints = viewModel.getCommentsMap();
        this.infoBoxContainer.style.display = FLEX;
        this.infoBoxContainer.style.minWidth = InfoBoxHandler.minCommentBoxWidth + PX;
        this.infoBoxContainer.style.flexBasis = "100%";
        let flexShrinkValue = (1 / (1 - parseFloat(this.settings.commentBoxSize))) - 1;
        this.infoBoxContainer.style.flexShrink = `${flexShrinkValue}`;
        this.infoBoxContainer.style.backgroundColor = this.settings.commentBoxBackgroundColor;
        this.infoBoxContainer.addEventListener(CONTEXT_MENU, e => {
            e.preventDefault();
            e.stopPropagation();
        });

        this.setCommentBoxMaxSize();
        let commentDataPoints = dataPoints.filter(d => d.hasComment());

        // filter duplicates
        commentDataPoints = commentDataPoints.filter((dp, index, self) =>
            index === self.findIndex((d) => (
                d.fullCategory === dp.fullCategory && JSON.stringify(d.commentsValues) == JSON.stringify(dp.commentsValues)
            ))
        )

        if (commentDataPoints.length > 0) {
            const commentsContainer = document.createElement(DIV);
            commentsContainer.classList.add('comment-box');
            commentsContainer.style.marginTop = topMargin + PX;
            commentsContainer.style.marginBottom = bottomMargin + PX;
            commentsContainer.style.padding = `${this.settings.commentBoxPadding}${PX}`;
            commentsContainer.style.paddingTop = "17px";
            if (this.settings.commentBoxListHorizontal && !this.settings.isCommentBoxVertical()) {
                commentsContainer.style.flexDirection = ROW;
            }
            this.dragLineElement.style.display = BLOCK;

            this.infoBoxContainer.append(commentsContainer);
            this.hasHighlight = dataPoints.filter((dp) => dp.isHighlighted).length > 0;
            this.setCommentBoxBorderRadius();
            this.setScrollBarToLeft(commentsContainer);
            this.setCommentBoxBorder();
            this.setCommentBoxShadow();

            //let maxElement: Element;
            //let maxTitleLenght: number = 0;
            for (const [index, dataPoint] of commentDataPoints.entries()) {
                const commentElement = <HTMLElement>this.drawComment(commentsContainer, index, dataPoint, axisBreak, this.settings.commentBoxTitle, this.settings.commentBoxShowVariance, this.settings.isCommentBoxVertical(), this.settings.commentBoxListHorizontal, this.settings.commentBoxItemsMargin);
                this.applyCommentStyles(commentElement, false);
            }
            if (this.settings.getRealInteractionSettingValue(this.settings.allowInteractiveCommentBox) && this.settings.showCommentBox) {
                this.drawCommentBoxSettings(topMargin);
            }

        } else {
            const noCommentsContainer = document.createElement(DIV);
            noCommentsContainer.style.display = FLEX;
            noCommentsContainer.style.height = 'calc(100% - 40' + PX + ')';
            noCommentsContainer.style.padding = '20' + PX;
            noCommentsContainer.style.justifyContent = CENTER;
            noCommentsContainer.style.alignItems = CENTER;
            let hasCommentsField = viewModel.scenarioOptions.comments && viewModel.scenarioOptions.comments.length > 0;
            noCommentsContainer.textContent = hasCommentsField ? '' : 'Please add a data field to the Comments placeholder';
            this.infoBoxContainer.appendChild(noCommentsContainer);
        }

        this.infoBoxContainer.onscroll = () => {
            (<any>window).commentBoxScrollTop = this.infoBoxContainer.scrollTop;
            (<any>window).commentBoxScrollLeft = this.infoBoxContainer.scrollLeft;
        };
        this.infoBoxContainer.scrollTop = (<any>window).commentBoxScrollTop;
        this.infoBoxContainer.scrollLeft = (<any>window).commentBoxScrollLeft;
    }

    public drawDragLine(fullWidth: number, fullHeight: number) {
        this.dragLineElement.style.display = BLOCK;
        let svgResizeLine = createSvgElement(this.infoBoxContainer, "resize-line");
        let line = undefined;
        if (this.settings.isCommentBoxVertical()) {
            svgResizeLine
                .attr(HEIGHT, fullHeight)
                .attr(WIDTH, `10`)
            line = drawLine(svgResizeLine, 5, 5, 0, fullHeight, 5, null, "resize-line");
        }
        else {
            svgResizeLine
                .attr(HEIGHT, `10`)
                .attr(WIDTH, fullWidth)
            line = drawLine(svgResizeLine, 0, fullWidth, 5, 5, fullWidth, null, "resize-line");
        }
        line.attr(STROKE_WIDTH, `1`)
            .attr(STROKE, `#808080`)
            .attr(STROKE_DASHARRAY, `5,3`);

        this.dragLineElement.appendChild(svgResizeLine.node());
        this.dragLineElement.classList.remove("comment-box-resize-line-vertical", "comment-box-resize-line-horizontal", "comment-box-resize-line-hidden")
        if (!this.settings.getRealInteractionSettingValue(this.settings.allowInteractiveCommentBox)) {
            this.dragLineElement.classList.add("comment-box-resize-line-hidden");
        }
        if (this.settings.commentBoxPlacement === CommentBoxPlacement.Left || this.settings.commentBoxPlacement === CommentBoxPlacement.Right) {
            this.dragLineElement.classList.add("comment-box-resize-line-vertical");
        }
        else {
            this.dragLineElement.classList.add("comment-box-resize-line-horizontal");
        }

        let flexShrinkValue = undefined;
        let commentBoxRatio = undefined;
        let draggable = d3.drag()
            .subject(function () {
                let t = d3.select(this);
                return { x: +t.attr("x"), y: +t.attr("y") };
            })
            .on(DRAGSTART, () => {
                this.hostElement.style.pointerEvents = NONE;
            })
            .on(DRAG, () => {
                let x = (<any>d3.event).sourceEvent.x;
                let y = (<any>d3.event).sourceEvent.y;
                let tooltipValue = 0;
                switch (this.settings.commentBoxPlacement) {
                    case CommentBoxPlacement.Right:
                        flexShrinkValue = fullWidth / (fullWidth - x) - 1;
                        commentBoxRatio = x / fullWidth;
                        tooltipValue = 1 - commentBoxRatio;
                        break;
                    case CommentBoxPlacement.Left:
                        flexShrinkValue = (fullWidth / x) - 1;
                        commentBoxRatio = 1 - (x / fullWidth);
                        tooltipValue = x / fullWidth;
                        break;
                    case CommentBoxPlacement.Above:
                        flexShrinkValue = (fullHeight / y) - 1;
                        commentBoxRatio = 1 - (y / fullHeight);
                        tooltipValue = y / fullHeight;
                        break;
                    case CommentBoxPlacement.Below:
                        flexShrinkValue = fullHeight / (fullHeight - y) - 1;
                        commentBoxRatio = y / fullHeight;
                        tooltipValue = 1 - commentBoxRatio;
                        break;
                    default:
                        break;
                }

                uiLib.showTooltip(this.infoBoxContainer, {
                    x: x, y: y - 20,
                }, `${(tooltipValue * 100).toFixed(0)}%`, 0, 1000);

                this.infoBoxContainer.style.flexShrink = `${flexShrinkValue}`;
                let commentContainer = d3.selectAll(".comment-box a");
                let number = d3.selectAll(".comment-box .comment-number");

                if ((this.settings.commentBoxListHorizontal && !this.settings.isCommentBoxVertical()) || (this.settings.isCommentBoxVertical() && this.infoBoxContainer.clientWidth < 180)) {
                    commentContainer.style(FLEX_DIRECTION, COLUMN);
                    commentContainer.style(MARGIN_RIGHT, this.settings.commentBoxItemsMargin.toString() + PX);
                    number.style(MARGIN, "5px auto 0 auto");
                }
                else {
                    number.style(MARGIN, null);
                    commentContainer.style(FLEX_DIRECTION, ROW);
                    commentContainer.style(MARGIN_BOTTOM, this.settings.commentBoxItemsMargin.toString() + PX);
                }
            })
            .on(DRAGEND, () => {
                this.hostElement.style.pointerEvents = null;
                if (commentBoxRatio !== undefined) {
                    this.settings.persistCommentBoxSize(commentBoxRatio.toString());
                }
            });
        if (this.settings.getRealInteractionSettingValue(this.settings.allowInteractiveCommentBox)) {
            d3.select(this.dragLineElement).call(draggable)
        }
    }

    setCommentBoxPlacement(hostWrapperElement: HTMLElement) {
        let flexDirection = "";
        switch (this.settings.commentBoxPlacement) {
            case CommentBoxPlacement.Right:
                flexDirection = ROW;
                break;
            case CommentBoxPlacement.Left:
                flexDirection = ROW_REVERSE;
                break;
            case CommentBoxPlacement.Above:
                flexDirection = COLUMN_REVERSE;
                break;
            case CommentBoxPlacement.Below:
                flexDirection = COLUMN;
                break;
        }
        hostWrapperElement.style.flexDirection = flexDirection;
    }
    setCommentBoxMaxSize() {
        this.infoBoxContainer.style.boxSizing = "border-box";
        let maxSize = `calc(100% - ${(this.settings.commentBoxShadow ? 15 : 10)}px)`
        if (this.settings.isCommentBoxVertical()) {
            this.infoBoxContainer.style.maxWidth = maxSize;
        } else {
            this.infoBoxContainer.style.maxHeight = maxSize;
        }
    }

    /**
     * This function sets the default and custom styling for comments
     * @param commentsContainer
     */
    private applyCommentStyles(commentsContainer: HTMLElement, fade: boolean) {
        const titleElements = commentsContainer.querySelectorAll('h6');
        const textElements = commentsContainer.querySelectorAll('p');

        if (this.settings.commentBoxCustomTitleStyle) {
            titleElements.forEach(title => {
                title.style.setProperty(FONT_FAMILY, this.settings.commentBoxTitleFontFamily);
                title.style.setProperty(COLOR, this.settings.commentBoxTitleFontColor);
            });

            this.drawCommentFontSize(commentsContainer, 'h6', /*maxElement, 6,*/ this.settings.commentBoxTitleFontSize);
        } else { // defaults
            titleElements.forEach(title => {
                title.style.setProperty(FONT_FAMILY, this.settings.labelFontFamily);
                title.style.setProperty(COLOR, this.settings.labelFontColor);
            });

            this.drawCommentFontSize(commentsContainer, 'h6', /*maxElement, 6,*/ this.settings.labelFontSize);
        }

        if (this.settings.commentBoxCustomTextStyle) {
            textElements.forEach(text => {
                text.style.setProperty(FONT_FAMILY, this.settings.commentBoxTextFontFamily);
                text.style.setProperty(COLOR, this.settings.commentBoxTextFontColor);
            });

            this.drawCommentFontSize(commentsContainer, 'p', /*maxElement, 6,*/ this.settings.commentBoxTextFontSize);
        } else {
            textElements.forEach(text => {
                text.style.setProperty(FONT_FAMILY, this.settings.labelFontFamily);
                text.style.setProperty(COLOR, this.settings.labelFontColor);
            });

            this.drawCommentFontSize(commentsContainer, 'p', /*maxElement, 6,*/ this.settings.labelFontSize);
        }

        if (fade && this.hasHighlight) {
            commentsContainer.style.opacity = '0.3';
        }
    }

    private isInverted(dataPoint: DataPoint): boolean {
        let inverted = false;
        if (this.settings.invert) {
            inverted = true;
            if (dataPoint.isInverted) {
                inverted = false;
            }
        } else if (dataPoint.isInverted) {
            inverted = true;
        }
        return inverted;
    }

    private getValueOrReferenceLabel(dataPoint: DataPoint, dataProperty: DataProperty): string {
        switch (dataProperty) {
            case DataProperty.Value:
                return dataPoint.valueLabel;
            case DataProperty.RelativeDifference:
            case DataProperty.AbsoluteDifference:
            case DataProperty.SecondRelativeDifference:
            case DataProperty.SecondAbsoluteDifference:
            case DataProperty.ThirdRelativeDifference:
            case DataProperty.ThirdAbsoluteDifference:
            case DataProperty.FourthRelativeDifference:
            case DataProperty.FourthAbsoluteDifference:
            case DataProperty.FifthRelativeDifference:
            case DataProperty.FifthAbsoluteDifference:
            case DataProperty.SixthRelativeDifference:
            case DataProperty.SixthAbsoluteDifference:
            case DataProperty.SeventhRelativeDifference:
            case DataProperty.SeventhAbsoluteDifference: { // return label of the first dataProperty value in the calculation (e.g.: PL - FC -> PL)
                let calculationIndex = getCalculationIndexPerComparison(dataProperty);
                let calculation = this.settings.calculations[calculationIndex];
                if (calculation != null) {
                    let dataPropertyToBeDisplayed = this.settings.getDataPropertyFromColumnName(calculation.split("-")[0]);
                    return dataPoint.getLabel(dataPropertyToBeDisplayed);
                }  // if the calculation is not explicitly saved for the comparison, return the value label, since it is the default first dataProperty (e.g.: ΔPY = AC - PY -> AC)
                return dataPoint.valueLabel;
            }
            case DataProperty.ReferenceValue:
                return dataPoint.referenceValueLabel;
            case DataProperty.SecondReferenceValue:
                return dataPoint.secondReferenceLabel;
            case DataProperty.ThirdReferenceValue:
                return dataPoint.thirdReferenceLabel;
            case DataProperty.FourthReferenceValue:
                return dataPoint.fourthReferenceLabel;
            case DataProperty.FifthReferenceValue:
                return dataPoint.fifthReferenceLabel;
            case DataProperty.SixthReferenceValue:
                return dataPoint.sixthReferenceLabel;
            case DataProperty.SeventhReferenceValue:
                return dataPoint.seventhReferenceLabel;
            default:
                return dataPoint.getLabel(dataProperty);
        }
    }

    private getAbsoluteDifferenceDataLabel(dataPoint: DataPoint, dataProperty: DataProperty): string {
        switch (dataProperty) {
            case DataProperty.Value:
            case DataProperty.ReferenceValue:
            case DataProperty.RelativeDifference:
            case DataProperty.AbsoluteDifference:
                return dataPoint.absoluteDifferenceLabel;
            case DataProperty.SecondReferenceValue:
            case DataProperty.SecondRelativeDifference:
            case DataProperty.SecondAbsoluteDifference:
                return dataPoint.secondAbsoluteDifferenceLabel;
            case DataProperty.ThirdReferenceValue:
            case DataProperty.ThirdRelativeDifference:
            case DataProperty.ThirdAbsoluteDifference:
                return dataPoint.thirdAbsoluteDifferenceLabel;
            case DataProperty.FourthReferenceValue:
            case DataProperty.FourthRelativeDifference:
            case DataProperty.FourthAbsoluteDifference:
                return dataPoint.fourthAbsoluteDifferenceLabel;
            case DataProperty.FifthReferenceValue:
            case DataProperty.FifthRelativeDifference:
            case DataProperty.FifthAbsoluteDifference:
                return dataPoint.fifthAbsoluteDifferenceLabel;
            case DataProperty.SixthReferenceValue:
            case DataProperty.SixthRelativeDifference:
            case DataProperty.SixthAbsoluteDifference:
                return dataPoint.sixthAbsoluteDifferenceLabel;
            case DataProperty.SeventhReferenceValue:
            case DataProperty.SeventhRelativeDifference:
            case DataProperty.SeventhAbsoluteDifference:
                return dataPoint.seventhAbsoluteDifferenceLabel;
            default:
                return "";
        }
    }

    private getRelativeDifferenceDataLabel(dataPoint: DataPoint, dataProperty: DataProperty): string {
        switch (dataProperty) {
            case DataProperty.Value:
            case DataProperty.ReferenceValue:
            case DataProperty.RelativeDifference:
            case DataProperty.AbsoluteDifference:
                return dataPoint.relativeDifferenceLabel;
            case DataProperty.SecondReferenceValue:
            case DataProperty.SecondRelativeDifference:
            case DataProperty.SecondAbsoluteDifference:
                return dataPoint.secondRelativeDifferenceLabel;
            case DataProperty.ThirdReferenceValue:
            case DataProperty.ThirdRelativeDifference:
            case DataProperty.ThirdAbsoluteDifference:
                return dataPoint.thirdRelativeDifferenceLabel;
            case DataProperty.FourthReferenceValue:
            case DataProperty.FourthRelativeDifference:
            case DataProperty.FourthAbsoluteDifference:
                return dataPoint.fourthRelativeDifferenceLabel;
            case DataProperty.FifthReferenceValue:
            case DataProperty.FifthRelativeDifference:
            case DataProperty.FifthAbsoluteDifference:
                return dataPoint.fifthRelativeDifferenceLabel;
            case DataProperty.SixthReferenceValue:
            case DataProperty.SixthRelativeDifference:
            case DataProperty.SixthAbsoluteDifference:
                return dataPoint.sixthRelativeDifferenceLabel;
            case DataProperty.SeventhReferenceValue:
            case DataProperty.SeventhRelativeDifference:
            case DataProperty.SeventhAbsoluteDifference:
                return dataPoint.seventhRelativeDifferenceLabel;
            default:
                return "";
        }
    }

    private getRelativeDifferenceValue(dataPoint: DataPoint, dataProperty: DataProperty): number {
        switch (dataProperty) {
            case DataProperty.Value:
            case DataProperty.ReferenceValue:
            case DataProperty.RelativeDifference:
            case DataProperty.AbsoluteDifference:
                return dataPoint.relativeDifference;
            case DataProperty.SecondReferenceValue:
            case DataProperty.SecondRelativeDifference:
            case DataProperty.SecondAbsoluteDifference:
                return dataPoint.secondRelativeDifference;
            case DataProperty.ThirdReferenceValue:
            case DataProperty.ThirdRelativeDifference:
            case DataProperty.ThirdAbsoluteDifference:
                return dataPoint.thirdRelativeDifference;
            case DataProperty.FourthReferenceValue:
            case DataProperty.FourthRelativeDifference:
            case DataProperty.FourthAbsoluteDifference:
                return dataPoint.fourthRelativeDifference;
            case DataProperty.FifthReferenceValue:
            case DataProperty.FifthRelativeDifference:
            case DataProperty.FifthAbsoluteDifference:
                return dataPoint.fifthRelativeDifference;
            case DataProperty.SixthReferenceValue:
            case DataProperty.SixthRelativeDifference:
            case DataProperty.SixthAbsoluteDifference:
                return dataPoint.sixthRelativeDifference;
            case DataProperty.SeventhReferenceValue:
            case DataProperty.SeventhRelativeDifference:
            case DataProperty.SeventhAbsoluteDifference:
                return dataPoint.seventhRelativeDifference;
            default:
                return 0;
        }
    }

    private getVarianceText(dataPoint: DataPoint, commentBoxVariance: CommentBoxVariance, dataProperty: DataProperty): string {
        let varianceText = "";
        let absoluteDifferenceDataLabel = this.getAbsoluteDifferenceDataLabel(dataPoint, dataProperty);
        let relativeDifferenceDataLabel = this.getRelativeDifferenceDataLabel(dataPoint, dataProperty);

        if (!isAdditionalMeasure(dataProperty)) {
            if (commentBoxVariance === CommentBoxVariance.RelativeVariance && relativeDifferenceDataLabel) {
                varianceText = `${relativeDifferenceDataLabel}${!this.settings.showPercentageInLabel ? `%` : ``}`
            }
            else if (commentBoxVariance === CommentBoxVariance.AbsoluteVariance && absoluteDifferenceDataLabel) {
                varianceText = absoluteDifferenceDataLabel;
            }
            else if (commentBoxVariance === CommentBoxVariance.AbsoluteAndRelativeVariance && absoluteDifferenceDataLabel && relativeDifferenceDataLabel) {
                varianceText = this.getRelativeDifferenceDataLabel(dataPoint, dataProperty);
                if (!this.settings.showPercentageInLabel) { // show the % in comment box even if the setting is turned off
                    varianceText += "%";
                }
                varianceText += ` ${TOTAL_VARIANCE_SEPARATOR} ${absoluteDifferenceDataLabel} `;
            }
        }
        return varianceText;
    }

    private getTitle(dataPoint: DataPoint, commentBoxTitle: CommentBoxTitle, commentBoxVariance: CommentBoxVariance): HTMLElement {
        let dataProperty = this.settings.getCommentMarkersDataProperty(dataPoint.fullCategory, dataPoint.parent?.firstGroupParent?.fullGroup);
        dataProperty = dataProperty === null ? DataProperty.Value : dataProperty;

        const isInverted = this.isInverted(dataPoint);
        const varianceText = this.getVarianceText(dataPoint, commentBoxVariance, dataProperty);
        const valueText = this.getValueOrReferenceLabel(dataPoint, dataProperty);
        const relativeDifference = this.getRelativeDifferenceValue(dataPoint, dataProperty);

        const titleElement = document.createElement('h6');
        const varianceValue = document.createElement('span');
        const titleCategory = document.createElement('span');

        let varianceColor = "";
        if (!isInverted) {
            varianceColor = relativeDifference < 0 ? this.settings.colorScheme.negativeColor : this.settings.colorScheme.positiveColor;
        } else {
            varianceColor = relativeDifference < 0 ? this.settings.colorScheme.positiveColor : this.settings.colorScheme.negativeColor;
        }

        titleCategory.innerText = dataPoint.category;
        titleElement.append(titleCategory);

        if (commentBoxTitle === CommentBoxTitle.TitleValue || commentBoxTitle === CommentBoxTitle.TitleValueVariance) {
            if (valueText) {
                titleCategory.innerText += " " + valueText;
            }
        }

        if ((commentBoxTitle === CommentBoxTitle.TitleValueVariance || commentBoxTitle === CommentBoxTitle.TitleVariance) && valueText && !isAdditionalMeasure(dataProperty)) {
            varianceValue.innerText = varianceText;
            let varianceIcon = createSvgElement(titleElement, "variance-icon")
                .attr(WIDTH, 20)
                .attr(HEIGHT, 20);
            drawVarianceIcon(varianceIcon, this.settings.commentBoxVarianceIcon, varianceColor, relativeDifference < 0);
            if (this.settings.getRealInteractionSettingValue(this.settings.allowInteractiveCommentBox)) {
                varianceIcon.classed("interactive", true);
                varianceIcon.on(CLICK, () => {
                    let commentBoxVarianceIcon = this.settings.commentBoxVarianceIcon;
                    commentBoxVarianceIcon = commentBoxVarianceIcon === 2 ? 0 : commentBoxVarianceIcon + 1;
                    this.settings.persistCommentBoxVarianceIcon(commentBoxVarianceIcon);
                });
                varianceIcon.on(MOUSEOVER, (d) => {
                    let bb = getBoundingBoxHtml(<HTMLElement>(<unknown>varianceIcon.node()));
                    uiLib.showTooltip(this.infoBoxContainer, {
                        x: bb.x - 10, y: Math.min(bb.y + 20, this.infoBoxContainer.clientHeight - 50)
                    }, `Click to change variance icon`, 250, 1000);
                });
                varianceValue.classList.add("interactive");
                varianceValue.onclick = () => {
                    commentBoxVariance = commentBoxVariance === 2 ? 0 : commentBoxVariance + 1;
                    this.settings.persistCommentBoxVariance(commentBoxVariance);
                }
                varianceValue.onmouseover = () => {
                    let bb = getBoundingBoxHtml(<HTMLElement>varianceValue);
                    uiLib.showTooltip(this.infoBoxContainer, {
                        x: bb.x - 10, y: Math.min(bb.y + 20, this.infoBoxContainer.clientHeight - 50)
                    }, `Click to change variance`, 250, 1000);
                }
            }
            varianceValue.classList.add("variance-value");
            titleElement.append(varianceValue);
        }

        return titleElement;
    }

    /**
     * This function will draw a single comment
     * @param container - target Element the comment will be rendered to
     * @param index - display number of the comment
     * @param dataPoint - contains comment data
     * @param axisBreak - in case the chart has a break point this will contain the value of the visually hidden value
     * @returns comment container HTMLAnchorElement
     */
    private drawComment(container: Element, index: number, dataPoint: DataPoint, axisBreak: number = 0, commentBoxTitle: CommentBoxTitle, commentBoxVariance: CommentBoxVariance, commentBoxVertical: Boolean, commentBoxListHorizontal: Boolean, commentBoxItemsMargin: number) {
        const commentContainer = document.createElement('a');
        const number = document.createElement('span');
        const textContainer = document.createElement('div');
        const text = document.createElement('p');

        if (commentBoxTitle !== CommentBoxTitle.Off) {
            const titleElementContainer = document.createElement('div');
            titleElementContainer.classList.add("comment-title")
            titleElementContainer.style.fontWeight = BOLD;
            let titleElement = this.getTitle(dataPoint, commentBoxTitle, commentBoxVariance);
            titleElementContainer.append(titleElement);
            textContainer.append(titleElementContainer);
        }

        textContainer.classList.add("comment-text-container");
        if (commentBoxTitle === CommentBoxTitle.Off) {
            textContainer.classList.add("no-title");
        }
        number.classList.add("comment-number");
        number.style.color = this.settings.colorScheme.highlightColor;
        number.style.borderColor = this.settings.colorScheme.highlightColor;
        number.style.fontFamily = this.settings.commentBoxTextFontFamily;
        let numberValue: number;
        let commentValue: string;

        if (dataPoint.commentsValues.length > 1) {
            if (typeof (dataPoint.commentsValues[0]) === 'number') {
                numberValue = dataPoint.commentsValues[0];
                commentValue = <string>dataPoint.commentsValues[1];
            }
        } else {
            numberValue = index + 1;
            commentValue = <string>dataPoint.commentsValues[0];
        }

        if (dataPoint.commentsValues.length > 1) {
            if (typeof (dataPoint.commentsValues[0]) === 'number') {
                numberValue = dataPoint.commentsValues[0];
            } else {
                numberValue = index + 1;
            }
            commentValue = <string>dataPoint.commentsValues[1];
        } else {
            numberValue = index + 1;
            commentValue = <string>dataPoint.commentsValues[0];
        }

        number.innerText = String(numberValue);
        if (!licensing.proFeaturesUnlocked() && licensing.getCurrentUser() && numberValue > FREE_MODE_COMMENT_LIMIT) {
            text.innerText = "Upgrade your Zebra BI for Office to display more than 3 comments here.";
        } else {
            text.innerText = commentValue;
        }

        if (commentBoxListHorizontal) {
            commentContainer.style.minWidth = "20vw";
        }

        if ((commentBoxListHorizontal && !commentBoxVertical) || (commentBoxVertical && this.infoBoxContainer.clientWidth < 180)) {
            commentContainer.style.flexDirection = "column";
            commentContainer.style.marginRight = `${commentBoxItemsMargin}${PX}`;
            number.style.margin = "10px auto";
        }
        else {
            commentContainer.style.marginBottom = `${commentBoxItemsMargin}${PX}`;
        }
        textContainer.append(text);
        commentContainer.append(number);
        commentContainer.append(textContainer);
        container.append(commentContainer);
        return commentContainer;
    }

    /**
     * This function will determin the font size of the element specified - currently most of the functionality has been put on hold
     * @param container container that holds the children the font size should be applied to
     * @param selector selector determining the childred that will be affected by the font size
     * @param maxSize upper limit of the font size - currently the acutal font size due to the autosize function being set on hold
     */
    private drawCommentFontSize(container: Element, selector: string, /*maxElement: Element, minSize: number,*/ maxSize: number) {
        /* const sample = <HTMLElement>maxElement.querySelector(selector);
         sample.style.whiteSpace = "nowrap";
         const containerSize = sample.getBoundingClientRect();

         const textWidth = containerSize.width;
         const availableSpace = sample.parentElement.parentElement.getBoundingClientRect().width;
         const measuredFontSize = parseFloat(window.getComputedStyle(sample, null).getPropertyValue('font-size'));
         sample.style.whiteSpace = "normal";
         const currentFontSize = maxSize; //Math.min(Math.max(availableSpace / textWidth * measuredFontSize, minSize), maxSize);*/

        const elements = container.querySelectorAll(selector);
        elements.forEach(element => {
            const htmlElement = (<HTMLElement>element);
            htmlElement.style.fontSize = maxSize + FONT_SIZE_UNIT;
        });
    }

    /**
     * This functon calculates the break point for longer texts. It accepts the text to be wrapped, the container width and the individual line height
     * Used for SVG text wrapping
     * @param text - the text that is in need to be wrapped
     * @param width - available width for a text row
     * @param lineHeight - the height of the text row
     * */
    private textWrap(text: any, width: number, lineHeight: number) {
        text.each(function () {
            var text = d3.select(this),
                words = text.text().split(/\s+/).reverse(),
                word,
                line = [],
                lineNumber = 0,
                y = text.attr("y"),
                tspan = text.text(null).append("tspan").attr("x", 0).attr("y", y);

            while (word = words.pop()) {
                line.push(word);
                tspan.text(line.join(" "));
                if (tspan.node().getComputedTextLength() > width) {
                    line.pop();
                    tspan.text(line.join(" "));
                    line = [word];
                    tspan = text.append("tspan").attr("x", 0).attr("y", y).attr("dy", ++lineNumber * lineHeight).text(word);
                }
            }
        });
    }

    /**
     * Calculate the awailable width the chart has after the comment box is turned on
     * @param settings - settings to see if comment box is turend of and if any changes to the width are necessary
     * @param width - awaiable width in total
     * @returns number - width of awailable space for the chart
     */
    public static GET_AVAILABLE_WIDTH(settings: VarianceSettings, width: number): number {
        if (settings.showCommentBox) {
            if (width / 3 < InfoBoxHandler.minCommentBoxWidth) {
                const adaptWidthToComment = width - InfoBoxHandler.minCommentBoxWidth;
                width = adaptWidthToComment > 0 ? adaptWidthToComment : 0; // chart width can't be smaller than 0
            } else {
                width = width * (2 / 3);
            }
        }

        return width;
    }

}
