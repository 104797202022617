import React from "react";
import ReactDOM from "react-dom/client";
import { TablesChooserScreen } from "./screen";
import "../tailwind.css";
import { TablesChooserStore } from "@zebrabi/tables-chooser";

const ROOT_ELEMENT_ID = "tables-chooser-react";

function addRootDOMElement() {
  document.body.querySelectorAll(`#${ROOT_ELEMENT_ID}`).forEach((element) => element.remove());
  const element = document.createElement("div");
  element.setAttribute("id", ROOT_ELEMENT_ID);
  document.body.prepend(element);
}

export function mountReactTablesChooser(setVisible: boolean = false) {
  if (setVisible) {
    TablesChooserStore.set({
      isVisible: true,
      selectedTable: ""
    });
  }

  addRootDOMElement();
  const reactRoot = ReactDOM.createRoot(document.getElementById(ROOT_ELEMENT_ID) as HTMLElement);
  reactRoot.render(
    <React.StrictMode>
      <TablesChooserScreen />
    </React.StrictMode>
  );
}
