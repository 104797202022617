import { ViewMode } from "@zebrabi/matrix-data";

import { ColorScheme } from "../library/interfaces";
import { CommentMarkerKey } from "./../interfaces";

// import * as helpers from "./../library/helpers";
// import * as h from "../helpers";
// import * as drawing from "./../library/drawing";
// import * as formatting from "./../library/formatting";
// import * as styles from "./../library/styles";
// import * as matrixViewModelHelpers from "./matrixViewModelHelpers";S
// import * as helpersVisual from "./../helpers";
// import { getValue } from "./../library/viewModelHelpers";
// import { ViewModel } from "./viewModel";
// import { DataPoint } from "./../charting/dataPoint";
// import { ChartGroup } from "./../charting/chartGroup";
// import { ChartHierarchy } from "./../charting/chartHierarchy";
// import { Plotter } from "./../charting/plotters/plotter";
// import { Header } from "./../charting/header";
// import { parseFormulas } from "./formulaHelpers";
// import { Visual } from "../visual";
// import { isRelativeMeasure, stringifyMap } from "./../helpers";
// import { isPercentAdditionalMeasure } from "../charting/charts/chart";
import { TopNSetting } from "./topNSetting"
import {
    Scenario, ShowTotals, GroupTitleDisplayOptions, DataProperty, TopNType, CategoryRowHeight, ColumnFormat, NumberOfDataProperties,
    DataLabelUnitOptions, ShowAsTableOptions, CategoryDisplayOptions, MarkerStyle, ChartStyle, DifferenceLabel,
    BLACK, LEFT, EMPTY, DEFAULT_FONT, DEFAULT_FONT_SIZE, PRO_FEATURES_SETTINGS, CHART_SETTINGS, LEGEND_HEADER_SETTINGS, LICENSE_SETTINGS, DATA_LABEL_SETTINGS,
    GRIDLINE_COLOR, MAJOR_GRIDLINE_COLOR, TITLE_SETTINGS, GROUP_TITLE_SETTINGS, CATEGORY_SETTINGS, DESIGN_SETTINGS,
    INTERACTION_SETTINGS, BOLD, CATEGORIES_METADATA, NORMAL, HIGHLIGHT_COLOR,
    CUSTOMER_DATA_LABELS_FONT_FAMILY, CUSTOMER_DATA_LABELS_FONT_COLOR, CUSTOMER_DATA_LABELS_FONT_SIZE, CUSTOMER_TITLE_FONT_FAMILY,
    CUSTOMER_TITLE_FONT_COLOR, CUSTOMER_TITLE_FONT_SIZE, CUSTOMER_GROUP_TITLE_FONT_FAMILY, CUSTOMER_GROUP_TITLE_FONT_COLOR, CUSTOMER_GROUP_TITLE_FONT_SIZE,
    AVERAGE_LINE_SETTINGS, DASHED_DASHARRAY, CONSTANT_LINE_SETTINGS, HorizontalLabelPostion, VerticalLabelPosition, LabelTextOption,
    MEDIAN_LINE_SETTINGS, PERCENTILE_LINE_SETTINGS, AVERAGE, CONSTANT, MEDIAN, PERCENTILE, GROUPS_METADATA, VarianceDisplayType, COMMENT_BOX_SETTINGS, DisplayUnits, PREVIOUS_YEAR, ACTUAL, FORECAST, PLAN, PLAN2, FORECAST2, FORECAST3, PLAN3, AC, CALCULATIONS, CommentBoxPlacement, CommentBoxTitle, VarianceIcon, CommentBoxVariance, GRAY, WHITE, TRANSPARENT
} from "../library/constants";

import { ViewModel } from "./viewModel";
import { DataPoint } from "../charting/dataPoint";
import { ChartGroup } from "../charting/chartGroup";
import { ChartHierarchy } from "../charting/chartHierarchy";
import { Plotter } from "../charting/plotters/plotter";
import { Header } from "../charting/header";
import { MeasureRoles } from "@zebrabi/data-helpers/fieldAssignment";

import {
    ScenarioOptions, AbsoluteChart, ValueChart, TextAlign, SortDirection, NegativeValuesFormat, ChartType,
    ACT_ABS_REL, TOTAL, MINIMUM_CATEGORY_HEIGHT, MINIMUM_CHART_WIDTH, MINIMUM_CHART_WIDTH_NO_LABELS,
    SORT_SETTINGS,
    Formula,
    FormulaCalculation,
    FormulaEditMode,
    ColumnSettings,
    ViewSettings,
    AdditionalMeasureViewSettings,
    AdditionalMeasureColumnSettings,
    CategoryFormatSettings,
    ColumnTotalEmphasize
} from "../definitions";

import * as helpers from "./../library/helpers";
import * as h from "../helpers";
import * as drawing from "./../library/drawing";
import * as formatting from "./../library/formatting";
import * as styles from "./../library/styles";
import * as matrixViewModelHelpers from "./matrixViewModelHelpers";
import * as helpersVisual from "./../helpers";
import { Visual } from "../visual";
import { isRelativeMeasure, stringifyMap } from "../helpers";
import { isPercentAdditionalMeasure } from "../charting/charts/chart";
import SettingsSubject from "@zebrabi/office-settings/subjects/SettingsSubject";
import { persistManagerInstance } from "@zebrabi/office-settings/PersistManager";
import { licensing as licensingOffice } from "@zebrabi/licensing/Licensing";
import { OrganizationStyleData } from "@zebrabi/data-helpers/organizationStyles";

export const TABLE_SETTINGS_NAME = "ZBITablesSettings";

export class VarianceSettings extends SettingsSubject<VarianceSettings> {
    public viewMode: ViewMode;

    // Pro features settings
    public proFeaturesEnabled: boolean;
    public proFeaturesUnlocked: boolean;
    public proFeaturesDisabledByUser: boolean;

    // Chart settings
    public invert: boolean;
    public suppressZeros: boolean;
    public suppressNulls: boolean;
    public suppressAbsoluteVariances: boolean;
    public suppressEmptyColumns: boolean;
    public showAsTable: boolean;
    public showAdSlide: boolean;
    public chartType: string;
    public groupsInColumns: boolean;
    public showGridlines: boolean;
    public showMajorGridlines: boolean;
    public gridlineDensity: number;
    public showGridlinesTable: boolean;
    public gridlineDensityTable: number;
    public fontSize: number;
    public showTotals: ShowTotals;
    public showColumnTotals: boolean;
    public columnTotalLabels: any;
    public showRowGrandTotal: boolean;
    public showColumnGrandTotal: boolean;
    public freezeGrandTotal: boolean;
    public grandTotalGap: boolean;
    public rowGrandTotalLabel: string;
    public columnGrandTotalLabel: string;
    public absoluteChart: AbsoluteChart;
    public valueChart: ValueChart;
    public valueChartIntegrated: boolean;
    public minChartWidth: number;
    public onlyOneColumn: boolean;
    public valueScenario: Scenario;
    public secondValueScenario: Scenario;
    public thirdValueScenario: Scenario;
    public fourthValueScenario: Scenario;
    public fifthValueScenario: Scenario;
    public sixthValueScenario: Scenario;
    public seventhValueScenario: Scenario;
    public valuePosition: number;
    public secondValuePosition: number;
    public thirdValuePosition: number;
    public fourthValuePosition: number;
    public fifthValuePosition: number;
    public sixthValuePosition: number;
    public seventhValuePosition: number;
    public referenceScenario: Scenario;
    public secondReferenceScenario: Scenario;
    public thirdReferenceScenario: Scenario;
    public calculations: string[];
    public calculationsString: string;
    public limitOutliers: boolean;
    public minOutlierValue: number;
    public maxOutlierValue: number;
    public gapBetweenColumnsPercent: number;
    public showPrefixes: boolean;
    public columnSettings: Map<string, ColumnSettings>;
    public columnTotalEmphasize: Map<string, ColumnTotalEmphasize>
    public columnTotalEmphasizeTable: Map<string, ColumnTotalEmphasize>
    public categoryFormatSettings: Map<string, CategoryFormatSettings>;
    public suppressLargeRelativeVariance: boolean;
    public suppressLargeRelativeVarianceValue: number;
    public fourthReferenceScenario: Scenario;
    public fifthReferenceScenario: Scenario;
    public sixthReferenceScenario: Scenario;
    public seventhReferenceScenario: Scenario;
    public referencePosition: number;
    public secondReferencePosition: number;
    public thirdReferencePosition: number;
    public fourthReferencePosition: number;
    public fifthReferencePosition: number;
    public sixthReferencePosition: number;
    public seventhReferencePosition: number;

    // Header settings
    public valueHeader: string;
    public referenceHeader: string;
    public absoluteDifferenceHeader: string;
    public relativeDifferenceHeader: string;
    public secondReferenceHeader: string;
    public secondAbsoluteDifferenceHeader: string;
    public secondRelativeDifferenceHeader: string;
    public thirdReferenceHeader: string;
    public thirdAbsoluteDifferenceHeader: string;
    public thirdRelativeDifferenceHeader: string;
    public fourthReferenceHeader: string;
    public fourthAbsoluteDifferenceHeader: string;
    public fourthRelativeDifferenceHeader: string;
    public fifthReferenceHeader: string;
    public fifthAbsoluteDifferenceHeader: string;
    public fifthRelativeDifferenceHeader: string;
    public sixthReferenceHeader: string;
    public sixthAbsoluteDifferenceHeader: string;
    public sixthRelativeDifferenceHeader: string;
    public seventhReferenceHeader: string;
    public seventhAbsoluteDifferenceHeader: string;
    public seventhRelativeDifferenceHeader: string;
    public additionalMeasure1Header: string;
    public additionalMeasure2Header: string;
    public additionalMeasure3Header: string;
    public additionalMeasure4Header: string;
    public additionalMeasure5Header: string;
    public additionalMeasure6Header: string;
    public additionalMeasure7Header: string;
    public additionalMeasure8Header: string;
    public additionalMeasure9Header: string;
    public additionalMeasure10Header: string;
    public additionalMeasure11Header: string;
    public additionalMeasure12Header: string;
    public additionalMeasure13Header: string;
    public additionalMeasure14Header: string;
    public additionalMeasure15Header: string;
    public additionalMeasure16Header: string;
    public additionalMeasure17Header: string;
    public additionalMeasure18Header: string;
    public additionalMeasure19Header: string;
    public additionalMeasure20Header: string;

    public previousYear: string;
    public actual: string;
    public forecast: string;
    public forecast2: string;
    public forecast3: string;
    public plan: string;
    public plan2: string;
    public plan3: string;
    public "actual-previousYear": string;
    public "actual-previousYear-percent": string;
    public "previousYear-actual": string;
    public "previousYear-actual-percent": string;
    public "actual-forecast": string;
    public "actual-forecast-percent": string;
    public "forecast-actual": string;
    public "forecast-actual-percent": string;
    public "actual-plan": string;
    public "actual-plan-percent": string;
    public "plan-actual": string;
    public "plan-actual-percent": string;
    public "previousYear-forecast": string;
    public "previousYear-forecast-percent": string;
    public "forecast-previousYear": string;
    public "forecast-previousYear-percent": string;
    public "previousYear-plan": string;
    public "previousYear-plan-percent": string;
    public "plan-previousYear": string;
    public "plan-previousYear-percent": string;
    public "forecast-plan": string;
    public "forecast-plan-percent": string;
    public "plan-forecast": string;
    public "plan-forecast-percent": string;
    public "actual-plan2": string;
    public "actual-plan2-percent": string;
    public "actual-plan3": string;
    public "actual-plan3-percent": string;
    public "actual-forecast2": string;
    public "actual-forecast2-percent": string;
    public "actual-forecast3": string;
    public "actual-forecast3-percent": string;
    public "plan2-actual": string;
    public "plan2-actual-percent": string;
    public "plan3-actual": string;
    public "plan3-actual-percent": string;
    public "forecast2-actual": string;
    public "forecast2-actual-percent": string;
    public "forecast3-actual": string;
    public "forecast3-actual-percent": string;
    public "plan2-previousYear": string;
    public "plan2-previousYear-percent": string;
    public "plan3-previousYear": string;
    public "plan3-previousYear-percent": string;
    public "forecast2-previousYear": string;
    public "forecast2-previousYear-percent": string;
    public "forecast3-previousYear": string;
    public "forecast3-previousYear-percent": string;
    public "previousYear-plan2": string;
    public "previousYear-plan2-percent": string;
    public "previousYear-plan3": string;
    public "previousYear-plan3-percent": string;
    public "previousYear-forecast2": string;
    public "previousYear-forecast2-percent": string;
    public "previousYear-forecast3": string;
    public "previousYear-forecast3-percent": string;
    public "forecast-plan2": string;
    public "forecast-plan2-percent": string;
    public "forecast-plan3": string;
    public "forecast-plan3-percent": string;
    public "forecast2-plan": string;
    public "forecast2-plan-percent": string;
    public "forecast2-plan2": string;
    public "forecast2-plan2-percent": string;
    public "forecast2-plan3": string;
    public "forecast2-plan3-percent": string;
    public "forecast3-plan": string;
    public "forecast3-plan-percent": string;
    public "forecast3-plan2": string;
    public "forecast3-plan2-percent": string;
    public "forecast3-plan3": string;
    public "forecast3-plan3-percent": string;
    public "plan-forecast2": string;
    public "plan-forecast2-percent": string;
    public "plan-forecast3": string;
    public "plan-forecast3-percent": string;
    public "plan2-forecast": string;
    public "plan2-forecast-percent": string;
    public "plan2-forecast2": string;
    public "plan2-forecast2-percent": string;
    public "plan2-forecast3": string;
    public "plan2-forecast3-percent": string;
    public "plan3-forecast": string;
    public "plan3-forecast-percent": string;
    public "plan3-forecast2": string;
    public "plan3-forecast2-percent": string;
    public "plan3-forecast3": string;
    public "plan3-forecast3-percent": string;
    public "forecast-forecast2": string;
    public "forecast-forecast2-percent": string;
    public "forecast-forecast3": string;
    public "forecast-forecast3-percent": string;
    public "forecast2-forecast": string;
    public "forecast2-forecast-percent": string;
    public "forecast2-forecast3": string;
    public "forecast2-forecast3-percent": string;
    public "forecast3-forecast": string;
    public "forecast3-forecast-percent": string;
    public "forecast3-forecast2": string;
    public "forecast3-forecast2-percent": string;
    public "plan-plan2": string;
    public "plan-plan2-percent": string;
    public "plan-plan3": string;
    public "plan-plan3-percent": string;
    public "plan2-plan": string;
    public "plan2-plan-percent": string;
    public "plan2-plan3": string;
    public "plan2-plan3-percent": string;
    public "plan3-plan": string;
    public "plan3-plan-percent": string;
    public "plan3-plan2": string;
    public "plan3-plan2-percent": string;

    public freezeHeaders: boolean;

    // Column adder settings
    public firstTimeShowingColumnAdder: boolean;

    // Title settings
    public showTitle: boolean;
    public titleWrap: boolean;
    public titleFontSize: number;
    public titleFontColor: string;
    public titleAlignment: string;
    public titleText: string;
    public titleFontFamily: string;
    public titleFontWeight: string;
    public titleFontStyle: string;
    public freezeTitle: boolean;

    // Group title settings
    public groupTitleFontSize: number;
    public groupTitleFontColor: string;
    public groupTitleAlignment: TextAlign;
    public groupTitleFontFamily: string;
    public groupTitleDisplayOptions: GroupTitleDisplayOptions;
    public groupTitleWidth: number;

    // Categories settings
    public showCategories: boolean;
    public categoriesDisplayOptions: CategoryDisplayOptions;
    public categoriesWidth: number;
    public categoriesRowHeight: CategoryRowHeight;
    public categoriesHeight: number;
    public categoriesIndent: number;
    public topNType: TopNType;
    public topNDataProperty: DataProperty;
    public plottedDataProperties: string[];
    public topNCategoriesToKeep: number;
    public topNSettings: TopNSetting[];
    public showTopNForm: boolean;
    public topNOtherLabel: string;
    public topNOtherShown: boolean;
    public freezeCategories: boolean;

    // Design settings
    public chartStyle: ChartStyle;
    public colorScheme: ColorScheme;
    public lightenOverlapped: boolean;
    public varianceDisplayType: VarianceDisplayType;
    public selectedOrganizationStyleId: number;

    // Data labels settings
    public showDataLabels: boolean;
    public labelFontColor: string;
    public displayUnits: DisplayUnits;
    public showUnits: DataLabelUnitOptions;
    public decimalPlaces: number;
    public decimalPlacesPercentage: number;
    public suppressSmallValues: boolean;
    public labelFontSize: number;
    public labelFontFamily: string;
    public integratedDifferenceLabel: DifferenceLabel;
    public isPercentageData: boolean;
    public labelPercentagePointUnit: string;
    public labelBackgroundTransparency: number;
    public negativeValuesFormat: NegativeValuesFormat;
    public rightAlignNumbers: boolean;
    public rightAlignParenthesisOffsetNormal: number;
    public rightAlignParenthesisOffsetBold: number;
    public showPercentageInLabel: boolean;

    // Sort settings
    public sortColumnName: string;
    public chartSort: SortDirection;
    public categorySort: SortDirection;
    public sortReferenceChart: number;

    // License settings
    public expiryDate: Date;
    public company: string;
    public disabledInViewMode: boolean;
    public licenseKey: string;
    public lastLicenseCheck: string;

    // Categories metadata
    public resultCategories: string[];
    public skippedCategories: string[];
    public flatResultCategories: string[];
    public invertedCategories: string[];
    public invertedTotalCategories: string[];
    public collapsedCategories: string[];
    public userOverrideCategories: string[];
    public highlightedCategories: string[];
    public highlightedCategoriesCustomColors: object[];
    public hasAutoResults: boolean;
    public hasAutoInverts: boolean;
    public userChangedExpandCollapse: boolean;

    // Gropups metadata
    public invertedGroups: string[];
    public groupNames: Map<string, string>;
    public groupNamesString: string;

    // Interaction settings
    public allowInteractions: boolean;
    public allowChartChange: boolean;
    public allowSliderChange: boolean;
    public allowVarianceCalculationChange: boolean;
    public allowSortChange: boolean;
    public allowExpandCollapseChange: boolean;
    public allowExpandCollapseRowsChange: boolean;
    public allowExpandCollapseColumnsChange: boolean;
    public allowColumnOrderChange: boolean;
    public focusModeFontZoomPercentage: number;
    public enableMeasureDrillThrough: boolean;
    public allowInteractiveCommentBox: boolean;

    public groupsCollapsed: string[];

    // Header settings
    public headers: Header[];
    public dragStarted: boolean;

    // ANALYTICS SETTINGS
    public analyticsDataProperty: DataProperty;

    // Average line settings
    public showAverageLine: boolean;
    public averageLineFill: string;
    public averageLineStyle: string;
    public averageLineTransparency: number;
    public averageLabelShow: boolean;
    public averageLabelColor: string;
    public averageLabelHorizontalPosition: HorizontalLabelPostion;
    public averageLabelVerticalPosition: VerticalLabelPosition;
    public averageLabelUnits: DisplayUnits;
    public averageLabelDecimalPlaces: number;
    public averageLabelTextOption: LabelTextOption;
    public averageLabelText: string;

    // Constant line settings
    public showConstantLine: boolean;
    public constantLineFill: string;
    public constantLineStyle: string;
    public constantLineValue: number;
    public constantLineTransparency: number;
    public constantLabelShow: boolean;
    public constantLabelColor: string;
    public constantLabelHorizontalPosition: HorizontalLabelPostion;
    public constantLabelVerticalPosition: VerticalLabelPosition;
    public constantLabelUnits: DisplayUnits;
    public constantLabelDecimalPlaces: number;
    public constantLabelTextOption: LabelTextOption;
    public constantLabelText: string;

    // Median line settings
    public showMedianLine: boolean;
    public medianLineFill: string;
    public medianLineStyle: string;
    public medianLineTransparency: number;
    public medianDataProperty: DataProperty;
    public medianLabelShow: boolean;
    public medianLabelColor: string;
    public medianLabelHorizontalPosition: HorizontalLabelPostion;
    public medianLabelVerticalPosition: VerticalLabelPosition;
    public medianLabelUnits: DisplayUnits;
    public medianLabelDecimalPlaces: number;
    public medianLabelTextOption: LabelTextOption;
    public medianLabelText: string;

    // Percentile line settings
    public showPercentileLine: boolean;
    public percentileLineFill: string;
    public percentileLineStyle: string;
    public percentileLinePercent: number;
    public percentileLineTransparency: number;
    public percentileDataProperty: DataProperty;
    public percentileLabelShow: boolean;
    public percentileLabelColor: string;
    public percentileLabelHorizontalPosition: HorizontalLabelPostion;
    public percentileLabelVerticalPosition: VerticalLabelPosition;
    public percentileLabelUnits: DisplayUnits;
    public percentileLabelDecimalPlaces: number;
    public percentileLabelTextOption: LabelTextOption;
    public percentileLabelText: string;
    public formulaCalculation: FormulaCalculation;
    columnSettingsString: string;
    categoryFormatSettingsString: string;
    public columnTotalEmphasizeString: string;
    public columnTotalEmphasizeTableString: string;

    // Comment Box
    public showCommentBox: boolean;
    public commentBoxTitle: CommentBoxTitle;
    public commentBoxCustomTitleStyle: boolean;
    public commentBoxTitleFontSize: number;
    public commentBoxTitleFontFamily: string;
    public commentBoxTitleFontColor: string;
    public commentBoxCustomTextStyle: boolean;
    public commentBoxTextFontSize: number;
    public commentBoxTextFontFamily: string;
    public commentBoxTextFontColor: string;
    public commentBoxPlacement: number;
    public commentBoxListHorizontal: boolean;
    public commentBoxSize: string;
    public commentBoxPadding: number;
    public commentBoxItemsMargin: number;
    public commentBoxBorderWidth: number;
    public commentBoxBorderColor: string;
    public commentBoxBorderRadius: number;
    public commentBoxShadow: boolean;
    public commentBoxBackgroundColor: string;
    public commentBoxShowVariance: CommentBoxVariance;
    public commentBoxVarianceIcon: VarianceIcon;

    // Comment markers
    public commentMarkersDataPropertiesString: string;
    public commentMarkersDataProperties: Map<string, DataProperty>;

    // deprecated fields
    public measure1Role: string;
    public measure2Role: string;
    public measure3Role: string;
    public measure4Role: string;
    public measure5Role: string;

    public measureRoles: MeasureRoles[];
    public usedMeasuresCount: number;

    public enableFiltering: boolean;
    public showCrossTables: boolean;

    public scenarioOptions: ScenarioOptions;

    get hasNoResults(): boolean {
        return this.resultCategories.length === 0 && !this.hasAutoResults;
    }

    get hasNoFlatResults(): boolean {
        return this.flatResultCategories.length === 0;
    }

    get hasFlatResults(): boolean {
        return this.flatResultCategories.length > 0;
    }

    get hasNoInverts(): boolean {
        return this.invertedCategories.length === 0 && this.invertedTotalCategories.length === 0 && !this.hasAutoInverts;
    }

    get isDrHichertStyle(): boolean {
        return this.chartStyle === ChartStyle.DrHichert;
    }

    get isUsingCustomStyle(): boolean {
        return this.chartStyle === ChartStyle.Custom;
    }

    // tslint:disable-next-line: max-func-body-length
    constructor(public viewModel: ViewModel) {
        super();
        // setting default values
        // We currently don't support company styles like before certification.
        // let showCompanyStyle = CUSTOMER_STYLE_VISIBLE.toString() === "true";
        let showCompanyStyle = false;
        // Pro features settings
        this.proFeaturesEnabled = true;
        this.proFeaturesUnlocked = true;
        this.proFeaturesDisabledByUser = false;

        // Chart settings
        this.invert = false;
        this.suppressZeros = true;
        this.suppressNulls = true;
        this.suppressAbsoluteVariances = false;
        this.suppressEmptyColumns = false;
        this.showAsTable = false;
        this.showAdSlide = false;
        this.chartType = ACT_ABS_REL;
        this.groupsInColumns = true;
        this.showGridlines = true;
        this.showMajorGridlines = true;
        this.gridlineDensity = 5;
        this.showGridlinesTable = true;
        this.gridlineDensityTable = 1;
        this.fontSize = DEFAULT_FONT_SIZE;
        this.showTotals = ShowTotals.Above;
        this.showColumnTotals = false;
        this.columnTotalLabels = {};
        this.showRowGrandTotal = false;
        this.showColumnGrandTotal = false;
        this.freezeGrandTotal = true;
        this.grandTotalGap = false;
        this.rowGrandTotalLabel = TOTAL;
        this.columnGrandTotalLabel = TOTAL;
        this.absoluteChart = AbsoluteChart.Bar;
        this.valueChart = ValueChart.Bar;
        this.valueChartIntegrated = false;
        this.onlyOneColumn = false;
        this.limitOutliers = false;
        this.minOutlierValue = null;
        this.maxOutlierValue = null;
        this.gapBetweenColumnsPercent = 30;
        this.showPrefixes = true;
        this.columnSettings = new Map<string, ColumnSettings>();
        this.categoryFormatSettings = new Map<string, CategoryFormatSettings>()
        this.columnTotalEmphasize = new Map<string, ColumnTotalEmphasize>()
        this.columnTotalEmphasizeTable = new Map<string, ColumnTotalEmphasize>()
        this.suppressLargeRelativeVariance = false;
        this.suppressLargeRelativeVarianceValue = 100;

        // Header settings
        this.valueHeader = null;
        this.referenceHeader = null;
        this.absoluteDifferenceHeader = null;
        this.relativeDifferenceHeader = null;
        this.secondReferenceHeader = null;
        this.secondAbsoluteDifferenceHeader = null;
        this.secondRelativeDifferenceHeader = null;
        this.thirdReferenceHeader = null;
        this.thirdAbsoluteDifferenceHeader = null;
        this.thirdRelativeDifferenceHeader = null;
        this.fourthReferenceHeader = null;
        this.fourthAbsoluteDifferenceHeader = null;
        this.fourthRelativeDifferenceHeader = null;
        this.fifthReferenceHeader = null;
        this.fifthAbsoluteDifferenceHeader = null;
        this.fifthRelativeDifferenceHeader = null;
        this.sixthReferenceHeader = null;
        this.sixthAbsoluteDifferenceHeader = null;
        this.sixthRelativeDifferenceHeader = null;
        this.seventhReferenceHeader = null;
        this.seventhAbsoluteDifferenceHeader = null;
        this.seventhRelativeDifferenceHeader = null;
        this.additionalMeasure1Header = null;
        this.additionalMeasure2Header = null;
        this.additionalMeasure3Header = null;
        this.additionalMeasure4Header = null;
        this.additionalMeasure5Header = null;
        this.additionalMeasure6Header = null;
        this.additionalMeasure7Header = null;
        this.additionalMeasure8Header = null;
        this.additionalMeasure9Header = null;
        this.additionalMeasure10Header = null;
        this.additionalMeasure11Header = null;
        this.additionalMeasure12Header = null;
        this.additionalMeasure13Header = null;
        this.additionalMeasure14Header = null;
        this.additionalMeasure15Header = null;
        this.additionalMeasure16Header = null;
        this.additionalMeasure17Header = null;
        this.additionalMeasure18Header = null;
        this.additionalMeasure19Header = null;
        this.additionalMeasure20Header = null;
        this.previousYear = null;
        this.actual = null;
        this.forecast = null;
        this.plan = null;
        this.plan2 = null;
        this.plan3 = null;
        this.forecast2 = null;
        this.forecast3 = null;
        for (let item of CALCULATIONS) {
            this[item] = null;
        }
        this.freezeHeaders = true;
        // Column adder settings
        this.firstTimeShowingColumnAdder = true;

        // Title settings
        this.showTitle = true;
        this.titleWrap = true;
        this.titleAlignment = LEFT;
        this.titleFontSize = 12;
        this.titleFontColor = BLACK;
        this.titleText = EMPTY;
        this.titleFontFamily = DEFAULT_FONT;
        this.titleFontWeight = NORMAL;
        this.titleFontStyle = NORMAL;
        this.freezeTitle = true;

        // Group title settings
        this.groupTitleAlignment = LEFT;
        this.groupTitleFontSize = 12;
        this.groupTitleFontColor = BLACK;
        this.groupTitleFontFamily = DEFAULT_FONT;
        this.groupTitleDisplayOptions = GroupTitleDisplayOptions.Auto;
        this.groupTitleWidth = 50;

        // Categories settings
        this.showCategories = true;
        this.categoriesDisplayOptions = CategoryDisplayOptions.Auto;
        this.categoriesWidth = 100;
        this.categoriesRowHeight = CategoryRowHeight.Auto;
        this.categoriesHeight = MINIMUM_CATEGORY_HEIGHT;
        this.categoriesIndent = 16;
        this.topNType = TopNType.Off;
        this.topNDataProperty = DataProperty.Value;
        this.plottedDataProperties = [];
        this.topNCategoriesToKeep = 5;
        this.topNSettings = [];
        this.topNOtherLabel = "Others";
        this.topNOtherShown = false;
        this.freezeCategories = true;
        this.showTopNForm = false;

        // Design settings
        this.colorScheme = {
            positiveColor: "#7aca00",
            negativeColor: "#ff0000",
            neutralColor: "#404040",
            markerColor: BLACK,
            lineColor: "#404040",
            axisColor: BLACK,
            gridlineColor: GRIDLINE_COLOR,
            dotChartColor: "#4080FF",
            majorGridlineColor: MAJOR_GRIDLINE_COLOR,
            useCustomScenarioColors: false,
            previousYearColor: BLACK,
            planColor: BLACK,
            plan2Color: BLACK,
            plan3Color: BLACK,
            forecastColor: BLACK,
            applyPatterns: true,
            highlightColor: HIGHLIGHT_COLOR,
        };
        this.chartStyle = showCompanyStyle ? ChartStyle.Company : ChartStyle.Zebra;
        this.lightenOverlapped = true;
        this.varianceDisplayType = VarianceDisplayType.Bar;

        // Data labels settings
        this.showDataLabels = true;
        this.labelFontColor = BLACK;
        this.displayUnits = DisplayUnits.Auto;
        this.showUnits = DataLabelUnitOptions.DataLabels;
        this.decimalPlaces = 1;
        this.decimalPlacesPercentage = 1;
        this.suppressSmallValues = true;
        this.labelFontSize = DEFAULT_FONT_SIZE;
        this.labelFontFamily = DEFAULT_FONT;
        this.integratedDifferenceLabel = DifferenceLabel.Absolute;
        this.isPercentageData = false;
        this.labelPercentagePointUnit = "pp";
        this.labelBackgroundTransparency = 20;
        this.negativeValuesFormat = NegativeValuesFormat.MinusSign;
        this.rightAlignNumbers = false;
        this.rightAlignParenthesisOffsetNormal = 0;
        this.rightAlignParenthesisOffsetBold = 0;
        this.showPercentageInLabel = false;

        // Sort settings
        this.sortColumnName = null;
        this.chartSort = SortDirection.Descending;
        this.categorySort = SortDirection.Descending;
        this.sortReferenceChart = null;

        // License settings
        this.company = "Free version";
        this.expiryDate = null;
        this.disabledInViewMode = false;
        this.licenseKey = "";
        this.lastLicenseCheck = "";

        // Categories metadata
        this.resultCategories = [];
        this.skippedCategories = [];
        this.flatResultCategories = [];
        this.invertedCategories = [];
        this.userOverrideCategories = [];
        this.highlightedCategories = [];
        this.highlightedCategoriesCustomColors = [];
        this.hasAutoResults = false;
        this.hasAutoInverts = false;
        this.userChangedExpandCollapse = false;

        // Groups metadata
        this.invertedGroups = [];
        this.groupNames = new Map<string, string>();
        this.groupNamesString = "";

        // Interaction settings
        this.allowInteractions = true;
        this.allowChartChange = true;
        this.allowSliderChange = true;
        this.allowVarianceCalculationChange = true;
        this.allowSortChange = true;
        this.allowExpandCollapseChange = true;
        this.allowExpandCollapseRowsChange = true;
        this.allowExpandCollapseColumnsChange = true;
        this.allowColumnOrderChange = true;
        this.focusModeFontZoomPercentage = 150;
        this.enableMeasureDrillThrough = false;
        this.allowInteractiveCommentBox = true;

        // Headers
        this.headers = [];

        // Analytics settings
        this.analyticsDataProperty = DataProperty.Value;
        // Average line settings
        this.showAverageLine = false;
        this.averageLineStyle = DASHED_DASHARRAY;
        this.averageLineTransparency = 0;
        this.averageLabelShow = true;
        this.averageLabelHorizontalPosition = HorizontalLabelPostion.Right;
        this.averageLabelVerticalPosition = VerticalLabelPosition.Below;
        this.averageLabelUnits = DisplayUnits.Auto;
        this.averageLabelDecimalPlaces = 1;
        this.averageLabelTextOption = LabelTextOption.NameAndValue;
        this.averageLabelText = AVERAGE;

        // Constant line settings
        this.showConstantLine = false;
        this.constantLineStyle = "";
        this.constantLineValue = 0;
        this.constantLineTransparency = 0;
        this.constantLabelShow = true;
        this.constantLabelHorizontalPosition = HorizontalLabelPostion.Right;
        this.constantLabelVerticalPosition = VerticalLabelPosition.Below;
        this.constantLabelUnits = DisplayUnits.Auto;
        this.constantLabelDecimalPlaces = 1;
        this.constantLabelTextOption = LabelTextOption.NameAndValue;
        this.constantLabelText = CONSTANT;

        // Median line settings
        this.showMedianLine = false;
        this.medianLineStyle = DASHED_DASHARRAY;
        this.medianLineTransparency = 0;
        this.medianLabelShow = true;
        this.medianLabelHorizontalPosition = HorizontalLabelPostion.Right;
        this.medianLabelVerticalPosition = VerticalLabelPosition.Below;
        this.medianLabelUnits = DisplayUnits.Auto;
        this.medianLabelDecimalPlaces = 1;
        this.medianLabelTextOption = LabelTextOption.NameAndValue;
        this.medianLabelText = MEDIAN;

        // Percentile line settings
        this.showPercentileLine = false;
        this.percentileLineStyle = "2,4";
        this.percentileLinePercent = 90;
        this.percentileLineTransparency = 0;
        this.percentileLabelShow = true;
        this.percentileLabelHorizontalPosition = HorizontalLabelPostion.Right;
        this.percentileLabelVerticalPosition = VerticalLabelPosition.Below;
        this.percentileLabelUnits = DisplayUnits.Auto;
        this.percentileLabelDecimalPlaces = 1;
        this.percentileLabelTextOption = LabelTextOption.NameAndValue;
        this.percentileLabelText = PERCENTILE;
        this.formulaCalculation = {
            formulas: [],
            expressionMappings: new Map<string, Map<string, string>>(),
            expressionElements: new Map<string, Map<string, Map<string, Map<string, DataPoint>>>>(),
            identityDataPoints: new Map<string, Map<string, Map<string, Map<string, DataPoint>>>>(),
        }

        // Comment box settings
        this.showCommentBox = true;
        this.commentBoxCustomTitleStyle = false;
        this.commentBoxTitle = CommentBoxTitle.TitleValueVariance;
        this.commentBoxTitleFontColor = BLACK;
        this.commentBoxTitleFontFamily = DEFAULT_FONT;
        this.commentBoxTitleFontSize = 18;
        this.commentBoxCustomTextStyle = false;
        this.commentBoxTextFontColor = BLACK;
        this.commentBoxTextFontFamily = DEFAULT_FONT;
        this.commentBoxTextFontSize = 16;

        // ADDITIONAL STUFF
        this.columnSettingsString = "";
        this.categoryFormatSettingsString = "";
        this.invertedCategories = [];
        this.invertedTotalCategories = [];
        this.collapsedCategories = [];
        this.calculationsString = "";

        // this.switchIndices = false;
        // this.valueName = "";
        // this.valueRole = "";
        // this.referenceName = "";
        // this.referenceRole = "";
        // this.secondReferenceName = "";
        // this.secondReferenceRole = "";
        this.minChartWidth = MINIMUM_CHART_WIDTH;

        this.measure1Role = MeasureRoles.Values;
        this.measure2Role = MeasureRoles.PreviousYear;
        this.measure3Role = MeasureRoles.Plan;
        this.measure4Role = MeasureRoles.Forecast;
        this.measure5Role = MeasureRoles.Comments;
        this.measureRoles = [];

        this.enableFiltering = Office.context.host === Office.HostType.Excel;

        this.viewMode = ViewMode.Edit;
        this.commentBoxPlacement = CommentBoxPlacement.Right;
        this.commentBoxSize = "0.66";
        this.commentBoxPadding = 10;
        this.commentBoxListHorizontal = false;
        this.commentBoxItemsMargin = 10;
        this.commentBoxBorderWidth = 0;
        this.commentBoxBorderColor = GRAY;
        this.commentBoxBorderRadius = 0;
        this.commentBoxShadow = false;
        this.commentBoxBackgroundColor = TRANSPARENT;
        this.commentBoxVarianceIcon = VarianceIcon.Triangle;
        this.commentBoxShowVariance = CommentBoxVariance.RelativeVariance;

        this.showCrossTables = Visual.getInstance().isChooserCrossTables;
        // Comment markers
        this.commentMarkersDataProperties = new Map<string, DataProperty>();

        // Overwrite settings with organization style if available
        let organizationStyleSettings = Visual.getInstance().getOrganizationStyleSettings();
        if (organizationStyleSettings) {
            this.setOrganizationStyleSettings(organizationStyleSettings);
        }

        /*return new Proxy(this, {
            get: (target, prop) => {
                return target[prop];
            },

            set: (obj: VarianceSettings, prop: string, value) => {
                const oldValue = obj[prop];

                if(oldValue !== value) {
                    obj[prop] = value;
                    this.notify(obj[prop]);

                    // don't save
                    if (!["viewModel", "headers"].includes(prop)){
                        this.persist(TABLE_SETTINGS_NAME);
                    }
                }

                return true;
            }
        });*/
    }

    public persist(skipTimer = false) {
        const newSettings = { ... this };
        delete newSettings.viewModel;
        delete newSettings.headers;
        delete newSettings.observers;

        persistManagerInstance.update({
            objectName: TABLE_SETTINGS_NAME, // IMPORTANT: make sure it's not named the same way the proxy is!!
            properties: JSON.parse(JSON.stringify(newSettings)),
        });

        if (skipTimer) {
            persistManagerInstance.flushQueue(); // override timer
        }
    }

    public parseColumnsSettings() {
        let columnsSettings: Map<string, ColumnSettings>;
        if (this.columnSettingsString === EMPTY) {
            columnsSettings = new Map<string, ColumnSettings>();
        }
        else {
            columnsSettings = helpersVisual.parseMapFromString(this.columnSettingsString);
        }
        if (this.viewModel.HasValueColumn) {
            let columnName = this.getNameFromScenario(this.viewModel.value.scenario);
            this.columnSettings.set(columnName, this.parseColumnSettings(columnName, columnsSettings, this.valueHeader, ColumnFormat.Value));
        }
        if (this.viewModel.HasReferenceColumn) {
            let columnName = this.getNameFromScenario(this.viewModel.reference.scenario);
            this.columnSettings.set(columnName, this.parseColumnSettings(columnName, columnsSettings, this.referenceHeader, ColumnFormat.Value));
            columnName = this.getColumnName(DataProperty.AbsoluteDifference);
            this.columnSettings.set(columnName, this.parseColumnSettings(columnName, columnsSettings, this.absoluteDifferenceHeader, ColumnFormat.AbsoluteDifference));
            columnName = this.getColumnName(DataProperty.RelativeDifference);
            this.columnSettings.set(columnName, this.parseColumnSettings(columnName, columnsSettings, this.relativeDifferenceHeader, ColumnFormat.RelativeDifference));
        }
        if (this.viewModel.HasSecondReferenceColumn) {
            let columnName = this.getNameFromScenario(this.viewModel.secondReference.scenario);
            this.columnSettings.set(columnName, this.parseColumnSettings(columnName, columnsSettings, this.secondReferenceHeader, ColumnFormat.Value));
            columnName = this.getColumnName(DataProperty.SecondAbsoluteDifference);
            this.columnSettings.set(columnName, this.parseColumnSettings(columnName, columnsSettings, this.secondAbsoluteDifferenceHeader, ColumnFormat.AbsoluteDifference));
            columnName = this.getColumnName(DataProperty.SecondRelativeDifference);
            this.columnSettings.set(columnName, this.parseColumnSettings(columnName, columnsSettings, this.secondRelativeDifferenceHeader, ColumnFormat.RelativeDifference));
        }
        if (this.viewModel.HasThirdReferenceColumn) {
            let columnName = this.getNameFromScenario(this.viewModel.thirdReference.scenario);
            this.columnSettings.set(columnName, this.parseColumnSettings(columnName, columnsSettings, this.thirdReferenceHeader, ColumnFormat.Value));
            columnName = this.getColumnName(DataProperty.ThirdAbsoluteDifference);
            this.columnSettings.set(columnName, this.parseColumnSettings(columnName, columnsSettings, this.thirdAbsoluteDifferenceHeader, ColumnFormat.AbsoluteDifference));
            columnName = this.getColumnName(DataProperty.ThirdRelativeDifference);
            this.columnSettings.set(columnName, this.parseColumnSettings(columnName, columnsSettings, this.thirdRelativeDifferenceHeader, ColumnFormat.RelativeDifference));
        }
        if (this.viewModel.HasFourthReferenceColumn) {
            let columnName = this.getNameFromScenario(this.viewModel.fourthReference.scenario);
            this.columnSettings.set(columnName, this.parseColumnSettings(columnName, columnsSettings, this.fourthReferenceHeader, ColumnFormat.Value));
            columnName = this.getColumnName(DataProperty.FourthAbsoluteDifference);
            this.columnSettings.set(columnName, this.parseColumnSettings(columnName, columnsSettings, this.fourthAbsoluteDifferenceHeader, ColumnFormat.AbsoluteDifference));
            columnName = this.getColumnName(DataProperty.FourthRelativeDifference);
            this.columnSettings.set(columnName, this.parseColumnSettings(columnName, columnsSettings, this.fourthRelativeDifferenceHeader, ColumnFormat.RelativeDifference));
        }
        if (this.viewModel.HasFifthReferenceColumn) {
            let columnName = this.getNameFromScenario(this.viewModel.fifthReference.scenario);
            this.columnSettings.set(columnName, this.parseColumnSettings(columnName, columnsSettings, this.fifthReferenceHeader, ColumnFormat.Value));
            columnName = this.getColumnName(DataProperty.FifthAbsoluteDifference);
            this.columnSettings.set(columnName, this.parseColumnSettings(columnName, columnsSettings, this.fifthAbsoluteDifferenceHeader, ColumnFormat.AbsoluteDifference));
            columnName = this.getColumnName(DataProperty.FifthRelativeDifference);
            this.columnSettings.set(columnName, this.parseColumnSettings(columnName, columnsSettings, this.fifthRelativeDifferenceHeader, ColumnFormat.RelativeDifference));
        }
        if (this.viewModel.HasSixthReferenceColumn) {
            let columnName = this.getNameFromScenario(this.viewModel.sixthReference.scenario);
            this.columnSettings.set(columnName, this.parseColumnSettings(columnName, columnsSettings, this.sixthReferenceHeader, ColumnFormat.Value));
            columnName = this.getColumnName(DataProperty.SixthAbsoluteDifference);
            this.columnSettings.set(columnName, this.parseColumnSettings(columnName, columnsSettings, this.sixthAbsoluteDifferenceHeader, ColumnFormat.AbsoluteDifference));
            columnName = this.getColumnName(DataProperty.SixthRelativeDifference);
            this.columnSettings.set(columnName, this.parseColumnSettings(columnName, columnsSettings, this.sixthRelativeDifferenceHeader, ColumnFormat.RelativeDifference));
        }
        if (this.viewModel.HasSeventhReferenceColumn) {
            let columnName = this.getNameFromScenario(this.viewModel.seventhReference.scenario);
            this.columnSettings.set(columnName, this.parseColumnSettings(columnName, columnsSettings, this.seventhReferenceHeader, ColumnFormat.Value));
            columnName = this.getColumnName(DataProperty.SeventhAbsoluteDifference);
            this.columnSettings.set(columnName, this.parseColumnSettings(columnName, columnsSettings, this.seventhAbsoluteDifferenceHeader, ColumnFormat.AbsoluteDifference));
            columnName = this.getColumnName(DataProperty.SeventhRelativeDifference);
            this.columnSettings.set(columnName, this.parseColumnSettings(columnName, columnsSettings, this.seventhRelativeDifferenceHeader, ColumnFormat.RelativeDifference));
        }

        this.viewModel.additionalMeasures.forEach((measure, index) => {
            let header = this[`additionalMeasure${index + 1}Header`];
            this.columnSettings.set(measure.fieldName, this.parseColumnSettings(measure.fieldName, columnsSettings, header, ColumnFormat.Value, true, index));
        });

        // We also read the settings for the columns that are currently not present. If a user adds a certain column, changes some settings for it, then removes it we will
        // still read these settings and preserver them if he ever re-adds the column.
        for (let [key, value] of columnsSettings) {
            if (!this.columnSettings.has(key)) {
                this.columnSettings.set(key, value);
            }
        }
        // // Customizations for special cases - CHECK if still needed
        // this.isPercentageData = this.isPercentageData || this.displayUnits === DisplayUnits.Percent;
        if (this.isAbsoluteChartWaterfall() || this.isValueChartWaterfall()) {
            this.showTotals = ShowTotals.Below;
        }

        this.topNSettings.forEach(s => {
            s.dataProperty = this.getDataPropertyFromColumnName(s.columnName);
        });
    }

    public migrateTopNNumberInFocusMode() {
        this.topNSettings.filter(s => s.numberInFocusMode === undefined).forEach(s => {
            s.numberInFocusMode = 20;
        });
    }

    public parseCategoryFormatSettings() {
        if (this.categoryFormatSettingsString === EMPTY) {
            this.categoryFormatSettings = new Map<string, CategoryFormatSettings>();
        }
        else {
            this.categoryFormatSettings = helpersVisual.parseMapFromString(this.categoryFormatSettingsString);
        }
        this.migrateHighlighted();
    }

    public migrateHighlighted() {
        // We migrated:
        // - list of categories that should be highlighted (also including bold),
        // - list of custom highlight colors,
        // into the new categoryFormatSetting map which holds additional settings (bold, italic...) per category
        this.highlightedCategories.forEach((el) => {
            let categoryFormatSettings = this.getEmptyDefaultCategoryFormatSettings()
            categoryFormatSettings.isBold = true;

            let customHighlightColor = this.highlightedCategoriesCustomColors.find(c => c[el])
            if (customHighlightColor !== undefined) {
                categoryFormatSettings.highlightColor = customHighlightColor[el];
            }
            else {
                categoryFormatSettings.highlightColor = HIGHLIGHT_COLOR;
            }

            this.categoryFormatSettings.set(el, categoryFormatSettings)
        })
        this.highlightedCategories = [];
        this.highlightedCategoriesCustomColors = [];
    }

    private parseColumnSettings(columnName: string, columnsSettings: Map<string, ColumnSettings>, header: string, format: ColumnFormat, isAdditionalMeasure: boolean = false, index: number = 0): ColumnSettings {
        let columnSettings: ColumnSettings | AdditionalMeasureColumnSettings;
        let defaultSettings = this.getDefaultColumnSettings(isAdditionalMeasure, format);
        if (columnsSettings.has(columnName)) {
            let currentSettings = columnsSettings.get(columnName);
            columnSettings = { ...defaultSettings, ...currentSettings };
        }
        else {
            columnSettings = defaultSettings;
        }
        if ("header" in columnSettings) {
            if (columnSettings.header === EMPTY || columnSettings.header === null) {
                columnSettings.header = header;
            }
            let defaultFormat = matrixViewModelHelpers.getDefaultColumnFormat(this.viewModel.valueSources, this.viewModel.scenarioOptions, index);
            if (columnSettings.format === ColumnFormat.NotSet) {
                columnSettings.format = defaultFormat;
            }
            let view = <AdditionalMeasureViewSettings>(this.showAsTable ? columnSettings.tableView : columnSettings.chartView);
            let units = view.displayUnits;
            if (columnSettings.format === ColumnFormat.Percent || columnSettings.format === ColumnFormat.RelativeDifference || units === DisplayUnits.Percent) {
                columnSettings.percentageFormat = formatting.getPercentageFormatOrNull(true, "", false, columnSettings.showPercentageInLabel === 1);
            }
            else {
                columnSettings.percentageFormat = EMPTY;
            }
        }
        else {
            if (this[columnName] === EMPTY || this[columnName] === null) {
                this[columnName] = header;
            }
        }
        return columnSettings;
    }

    private getDefaultColumnSettings(isAdditionalMeasure: boolean, format: ColumnFormat): ColumnSettings | AdditionalMeasureColumnSettings {
        if (isAdditionalMeasure) {
            return {
                header: EMPTY,
                invert: false,
                order: undefined,
                format: ColumnFormat.NotSet,
                percentageFormat: EMPTY,
                showPercentageInLabel: 1,
                scaleGroup: -1,
                suppressOthers: false,
                tableView: this.getDefaultAdditionalMeasureColumnView(),
                chartView: this.getDefaultAdditionalMeasureColumnView(),
            };
        }
        else {
            return {
                invert: false,
                order: undefined,
                scaleGroup: 1,
                format: format,
                suppressOthers: false,
                tableView: this.getDefaultColumnView(),
                chartView: this.getDefaultColumnView(),
            };
        }
    }

    private getEmptyDefaultCategoryFormatSettings(): CategoryFormatSettings {
        return {
            isBold: false,
            isItalic: false,
            textColor: "",
            highlightColor: "",
            topBorder: false,
            displayUnits: undefined,
            decimalPlaces: undefined
        }
    }

    private getDefaultColumnView(): ViewSettings {
        return {
            bold: false,
            textColor: EMPTY,
            backgroundFill: EMPTY,
            markerStyle: MarkerStyle.None,
            border: EMPTY,
            showAsTable: ShowAsTableOptions.NotSet,
            hidden: false,
            hiddenFromGroups: [],
        };
    }

    private getDefaultAdditionalMeasureColumnView(): AdditionalMeasureViewSettings {
        return {
            bold: false,
            decimalPlaces: 1,
            displayUnits: DisplayUnits.Default,
            textColor: EMPTY,
            backgroundFill: EMPTY,
            markerStyle: MarkerStyle.None,
            border: EMPTY,
            showAsTable: ShowAsTableOptions.NotSet,
            hidden: false,
            valueChart: ValueChart.Bar,
            absoluteChart: AbsoluteChart.Bar,
            hiddenFromGroups: [],
        };
    }

    public samePosition(pos1: number, pos2: number): boolean {
        if (!pos1 && !pos2) {
            return true;
        } else if (pos1 === pos2) {
            return true;
        } else {
            return false;
        }
    }

    // tslint:disable-next-line: max-func-body-length
    public readCalculationScenarios(options: ScenarioOptions) {
        this.valueScenario = options.value.scenario;
        this.secondValueScenario = options.value.scenario;
        this.thirdValueScenario = options.value.scenario;
        this.fourthValueScenario = options.value.scenario;
        this.fifthValueScenario = options.value.scenario;
        this.sixthValueScenario = options.value.scenario;
        this.seventhValueScenario = options.value.scenario;
        this.valuePosition = 0;
        this.secondValuePosition = 0;
        this.thirdValuePosition = 0;
        this.fourthValuePosition = 0;
        this.fifthValuePosition = 0;
        this.sixthValuePosition = 0;
        this.seventhValuePosition = 0;
        this.referenceScenario = options.reference.scenario;
        this.secondReferenceScenario = options.secondReference.scenario ? options.secondReference.scenario : options.reference.scenario;
        this.thirdReferenceScenario = options.thirdReference.scenario ? options.thirdReference.scenario : options.reference.scenario;
        this.fourthReferenceScenario = options.fourthReference.scenario ? options.fourthReference.scenario : options.reference.scenario;
        this.fifthReferenceScenario = options.fifthReference.scenario ? options.fifthReference.scenario : options.reference.scenario;
        this.sixthReferenceScenario = options.sixthReference.scenario ? options.sixthReference.scenario : options.reference.scenario;
        this.seventhReferenceScenario = options.seventhReference.scenario ? options.seventhReference.scenario : options.reference.scenario;
        this.referencePosition = options.reference.position;
        this.secondReferencePosition = options.secondReference.position;
        this.thirdReferencePosition = options.thirdReference.position;
        this.fourthReferencePosition = options.fourthReference.position;
        this.fifthReferencePosition = options.fifthReference.position;
        this.sixthReferencePosition = options.sixthReference.position;
        this.seventhReferencePosition = options.seventhReference.position;

        let presentScenarios = [options.value.scenario, options.reference.scenario, options.secondReference.scenario, options.thirdReference.scenario, options.fourthReference.scenario, options.fifthReference.scenario, options.sixthReference.scenario, options.seventhReference.scenario];
        let presentPositions = [options.value.position, options.reference.position, options.secondReference.position, options.thirdReference.position, options.fourthReference.position, options.fifthReference.position, options.sixthReference.position, options.seventhReference.position];
        let calculationsString = this.calculationsString; //getValue<string>(objects, CHART_SETTINGS, "calculations", EMPTY);
        if (calculationsString === EMPTY) {
            this.calculations = [];
        }
        else {
            this.calculations = JSON.parse(calculationsString);
            for (let i = 0; i < this.calculations.length; i++) {
                let calculation = this.calculations[i];
                if (!calculation)
                    continue;

                let split = calculation.split("-");
                let valueScenario = this.getScenarioFromName(split[0]);
                let referenceScenario = this.getScenarioFromName(split[1]);
                let valuePosition = this.getPositionIndexFromCalculationName(split[0]);
                let referencePosition = this.getPositionIndexFromCalculationName(split[1]);

                // Edge cases where a measure has been moved from PL to FC or vise-versa
                if (!presentScenarios.some((sc, ix) => valueScenario === sc && this.samePosition(valuePosition, presentPositions[ix]))
                    || !presentScenarios.some((sc, ix) => referenceScenario === sc && this.samePosition(referencePosition, presentPositions[ix]))) {
                    return;
                }
                if (i === 0) {
                    this.valueScenario = valueScenario;
                    this.referenceScenario = referenceScenario;
                    this.valuePosition = valuePosition;
                    this.referencePosition = referencePosition;
                }
                else if (i === 1) {
                    this.secondValueScenario = valueScenario;
                    this.secondReferenceScenario = referenceScenario;
                    this.secondValuePosition = valuePosition;
                    this.secondReferencePosition = referencePosition;
                }
                else if (i === 2) {
                    this.thirdValueScenario = valueScenario;
                    this.thirdReferenceScenario = referenceScenario;
                    this.thirdValuePosition = valuePosition;
                    this.thirdReferencePosition = referencePosition;
                }
                else if (i === 3) {
                    this.fourthValueScenario = valueScenario;
                    this.fourthReferenceScenario = referenceScenario;
                    this.fourthValuePosition = valuePosition;
                    this.fourthReferencePosition = referencePosition;
                }
                else if (i === 4) {
                    this.fifthValueScenario = valueScenario;
                    this.fifthReferenceScenario = referenceScenario;
                    this.fifthValuePosition = valuePosition;
                    this.fifthReferencePosition = referencePosition;
                }
                else if (i === 5) {
                    this.sixthValueScenario = valueScenario;
                    this.sixthReferenceScenario = referenceScenario;
                    this.sixthValuePosition = valuePosition;
                    this.sixthReferencePosition = referencePosition;
                }
                else if (i === 6) {
                    this.seventhValueScenario = valueScenario;
                    this.seventhReferenceScenario = referenceScenario;
                    this.seventhValuePosition = valuePosition;
                    this.seventhReferencePosition = referencePosition;
                }
            }
        }
        if (presentScenarios.indexOf(this.valueScenario) < 0 || presentScenarios.indexOf(this.referenceScenario) < 0) {
            this.valueScenario = options.value.scenario;
            this.referenceScenario = options.reference.scenario;
        }
        if (presentScenarios.indexOf(this.secondValueScenario) < 0 || presentScenarios.indexOf(this.secondReferenceScenario) < 0) {
            this.secondValueScenario = options.value.scenario;
            this.secondReferenceScenario = options.secondReference.scenario ? options.secondReference.scenario : options.reference.scenario;
        }
        if (presentScenarios.indexOf(this.thirdValueScenario) < 0 || presentScenarios.indexOf(this.thirdReferenceScenario) < 0) {
            this.thirdValueScenario = options.value.scenario;
            this.thirdReferenceScenario = options.reference.scenario;
        }
        if (presentScenarios.indexOf(this.fourthValueScenario) < 0 || presentScenarios.indexOf(this.fourthReferenceScenario) < 0) {
            this.fourthValueScenario = options.value.scenario;
            this.fourthReferenceScenario = options.reference.scenario;
        }
        if (presentScenarios.indexOf(this.fifthValueScenario) < 0 || presentScenarios.indexOf(this.fifthReferenceScenario) < 0) {
            this.fifthValueScenario = options.value.scenario;
            this.fifthReferenceScenario = options.reference.scenario;
        }
        if (presentScenarios.indexOf(this.sixthValueScenario) < 0 || presentScenarios.indexOf(this.sixthReferenceScenario) < 0) {
            this.sixthValueScenario = options.value.scenario;
            this.sixthReferenceScenario = options.reference.scenario;
        }
        if (presentScenarios.indexOf(this.seventhValueScenario) < 0 || presentScenarios.indexOf(this.seventhReferenceScenario) < 0) {
            this.seventhValueScenario = options.value.scenario;
            this.seventhReferenceScenario = options.reference.scenario;
        }
    }

    public getRealInteractionSettingValue(settingValue: boolean): boolean {
        return true && (this.viewMode !== ViewMode.View || this.allowInteractions && settingValue);
    }

    public getAdditionalMeasurePercentageInLabel(dataProperty: DataProperty): number {
        return this.getColumnSetting(dataProperty, "showPercentageInLabel");
    }

    public persistAdditionalMeasurePercentageInLabel(dataProperty: DataProperty, value: number) {
        this.changeColumnSetting(dataProperty, "showPercentageInLabel", value);
        this.persistColumnSettings();
    }

    public resultsChange(d: DataPoint) {
        if (d.isResult) {
            this.resultCategories = this.resultCategories.filter(category => category !== d.category);
        }
        else {
            this.resultCategories.push(d.category);
        }
        if (!this.isUserOverriden(d)) {
            this.userOverrideCategories.push(d.category);
        }
    }

    public skippedChange(category: string, isSkipped: boolean) {
        if (isSkipped) {
            this.skippedCategories = this.skippedCategories.filter(c => c !== category);
        }
        else {
            this.skippedCategories.push(category);
        }
    }

    public flatResultsChange(d: DataPoint) {
        if (this.isFlatResult(d.category)) {
            this.flatResultCategories = this.flatResultCategories.filter(category => category !== d.category);
        }
        else {
            this.flatResultCategories.push(d.category);
        }
    }

    public isResult(category: string): boolean {
        return this.resultCategories.indexOf(category) > -1;
    }

    public isSkipped(category: string): boolean {
        return this.skippedCategories.indexOf(category) > -1;
    }

    public isFlatResult(category: string): boolean {
        return this.flatResultCategories.indexOf(category) > -1;
    }

    public isCategoryHighlighted(category: string): boolean {
        return !!category && this.getCategoryFormatHighlightColor(category) !== null;
    }

    public invertedCategoryChange(d: DataPoint) {
        if (this.isInverted(d.category)) {
            this.invertedCategories = this.invertedCategories.filter(category => category !== d.category);
        }
        else {
            this.invertedCategories.push(d.category);
        }
        if (!this.isUserOverriden(d)) {
            this.userOverrideCategories.push(d.category);
        }
    }

    public invertedTotalCategoryChange(d: DataPoint) {
        if (this.isInvertedTotal(d.category, d.hierarchyIdentity)) {
            this.invertedTotalCategories = this.invertedTotalCategories.filter(key => key !== `${d.hierarchyIdentity}:${d.category}`);
        }
        else {
            this.invertedTotalCategories.push(`${d.hierarchyIdentity}:${d.category}`);
        }
        if (!this.isUserOverriden(d)) {
            this.userOverrideCategories.push(d.category);
        }
    }

    public invertedGroupChange(g: ChartGroup) {
        if (this.invertedGroups.indexOf(g.fullGroup) > -1) {
            this.invertedGroups = this.invertedGroups.filter(group => group !== g.fullGroup);
        }
        else {
            this.invertedGroups.push(g.fullGroup);
        }
    }

    public isInverted(category: string): boolean {
        return this.invertedCategories.indexOf(category) > -1;
    }

    public isInvertedTotal(category: string, hierarchyIdentity): boolean {
        return this.invertedTotalCategories.indexOf(`${hierarchyIdentity}:${category}`) > -1;
    }

    public collapsedChange(d: DataPoint) {
        if (this.isCollapsed(d.fullCategory)) {
            this.collapsedCategories = this.collapsedCategories.filter(category => category !== d.fullCategory);
        }
        else {
            this.collapsedCategories.push(d.fullCategory);
        }
    }

    public collapsedChangeGroup(chartGroup: ChartGroup) {
        if (chartGroup.isCollapsed) {
            this.groupsCollapsed = this.groupsCollapsed.filter(g => g !== chartGroup.fullGroup);
        }
        else {
            this.groupsCollapsed.push(chartGroup.fullGroup);
        }
    }

    public isGroupCollapsed(chartGroup: ChartGroup): boolean {
        // return this.groupsCollapsed.indexOf(chartGroup.fullGroup) > -1;
        return false;
    }

    public isGroupInverted(chartGroup: ChartGroup): boolean {
        return this.invertedGroups.indexOf(chartGroup.fullGroup) > -1;
    }

    public isGroupOrParentCollapsed(chartGroup: ChartGroup): boolean {
        if (!chartGroup) {
            return false;
        }
        if (chartGroup.isCollapsed) {
            return true;
        }
        else if (chartGroup.level > 0 && (<ChartGroup>chartGroup).parent) {
            return this.isGroupOrParentCollapsed(<ChartGroup>(<ChartGroup>chartGroup).parent);
        }
        else {
            return false;
        }
    }

    public hasCollapsedAncestor(chartGroup: ChartGroup): boolean {
        if (chartGroup.level > 1 && (<ChartGroup>chartGroup).parent && (<ChartGroup>chartGroup.parent).parent) {
            return this.isGroupOrParentCollapsed(<ChartGroup>(<ChartGroup>(<ChartGroup>chartGroup).parent).parent);
        }
        return false;
    }

    public hiddenColumnsChange(dataProperty: number) {
        let settings = this.getColumnSettings(dataProperty);
        let currentValue = this.showAsTable ? settings.tableView.hidden : settings.chartView.hidden;
        this.changeColumnViewSettingAndPersist(dataProperty, "hidden", !currentValue);
    }

    public isColumnHidden(dataProperty: DataProperty) {
        let settings = this.getColumnSettings(dataProperty);
        return this.showAsTable ? settings.tableView.hidden : settings.chartView.hidden;
    }

    public removedColumnsChangeGroup(groupName: string, dataProperty: DataProperty) {
        let hiddenFromGroups = this.getColumnViewSetting(dataProperty, "hiddenFromGroups");
        if (helpersVisual.contains(hiddenFromGroups, groupName)) {
            hiddenFromGroups = hiddenFromGroups.filter(group => group !== groupName);
        }
        else {
            hiddenFromGroups.push(groupName);
        }
        this.changeColumnViewSetting(dataProperty, "hiddenFromGroups", hiddenFromGroups);
        this.persistColumnSettings();
    }

    public isColumnHiddenFromGroup(group: ChartGroup, dataProperty: DataProperty) {
        if (!this.proVersionActive()) {
            return false;
        }
        let groups: ChartGroup[] = [];
        if (group.isColumnGrandTotal) {
            groups = [group];
        }
        else if (group.isSubtotal) {
            groups = helpersVisual.getParents(group);
        }
        else {
            groups = [group].concat(helpersVisual.getParents(group));
        }
        let hiddenFromGroups = this.getColumnViewSetting(dataProperty, "hiddenFromGroups");
        return groups.some(g => h.contains(hiddenFromGroups, g.group));
    }

    public isCollapsed(category: string): boolean {
        return this.collapsedCategories.indexOf(category) > -1;
    }

    public noInvertsOrResultsOrSkips(): boolean {
        return this.hasNoResults && this.hasNoInverts && this.hasNoFlatResults && this.hasNoSkips();
    }

    public hasNoSkips(): boolean {
        return this.skippedCategories.length === 0;
    }

    public hasInvertOrResult(): boolean {
        return !this.hasNoResults || !this.hasNoInverts;
    }

    public hasInvertOrResultOrSkip(): boolean {
        return !this.noInvertsOrResultsOrSkips();
    }

    public isUserOverriden(d: DataPoint): boolean {
        return this.userOverrideCategories.indexOf(d.category) > -1;
    }

    public shouldSort(): boolean {
        return (this.chartSort !== SortDirection.None || this.categorySort !== SortDirection.None) && this.hasNoResults;
    }

    public handleSortHeaderClick(sortColumn: string, referenceId: number) {
        if (this.clickedOnCurrentSortHeader(sortColumn, referenceId)) {
            this.chartSort = this.getNextSort();
        }
        else {
            this.chartSort = SortDirection.Descending;
        }
        this.categorySort = this.chartSort;
        this.sortColumnName = sortColumn;
        this.sortReferenceChart = referenceId;
    }

    public clickedOnCurrentSortHeader(sortColumn: string, referenceId: number): boolean {
        if (sortColumn !== this.sortColumnName) {
            return false;
        }
        return referenceId === this.sortReferenceChart;
    }

    public getNextSort(): SortDirection {
        return (this.chartSort + 1) % 3;
    }

    public getColumnFormat(dataProperty: DataProperty): number {
        return this.getColumnSetting(dataProperty, "format");
    }

    public getTopNSetting(level: number) {
        return this.topNSettings.find(e => e.level === level);
    }

    public setTopNSetting(category: string, dataProperty: DataProperty, level: number, number: number, numberInFocusMode: number, type: TopNType) {
        let index = this.topNSettings.findIndex(e => e.level === level);
        let columnName = this.getColumnName(dataProperty);
        if (index > -1) {
            this.topNSettings[index].category = category;
            this.topNSettings[index].dataProperty = dataProperty;
            this.topNSettings[index].columnName = columnName;
            this.topNSettings[index].level = level;
            this.topNSettings[index].number = number;
            this.topNSettings[index].numberInFocusMode = numberInFocusMode;
            this.topNSettings[index].type = type;
        } else {
            let setting = new TopNSetting();
            setting.category = category;
            setting.dataProperty = dataProperty;
            setting.columnName = columnName;
            setting.level = level;
            setting.number = number;
            setting.numberInFocusMode = numberInFocusMode;
            setting.type = type;
            this.topNSettings.push(setting);
        }
    }

    public persistTopNSettings() {
        let properties: any = {};
        properties.topNSettings = JSON.stringify(this.topNSettings);
        properties.showTopNForm = false;
        this.showTopNForm = false;
        Visual.getInstance().constructViewModelAndUpdate(this);
    }

    public persistTopNForm(val: boolean) {
        let properties: any = {};
        properties.showTopNForm = val;
        /*        let modifiedProperties = {
                    merge: [{
                        objectName: CATEGORY_SETTINGS,
                        properties: properties,
                        selector: null,
                    }],
                };*/
        // this.host.persistProperties(modifiedProperties);
    }

    public getMarkerStyle(dataProperty: DataProperty): MarkerStyle {
        return this.getColumnViewSetting(dataProperty, "markerStyle");
    }

    public isHeaderBold(dataProperty: DataProperty): boolean {
        return this.getColumnViewSetting(dataProperty, "bold");
    }

    public getColumnDecimalPlaces(dataProperty: DataProperty): number {
        if (helpers.isAdditionalMeasure(dataProperty)) {
            let columnFormat = this.getColumnFormat(dataProperty);
            let places = this.getColumnViewSetting(dataProperty, "decimalPlaces");
            if (places === -1) {
                if (isPercentAdditionalMeasure(dataProperty, this)) {
                    return this.decimalPlacesPercentage;
                }
                return this.getDecimalPlaces(columnFormat);
            }
            return places;
        }
        else if (isRelativeMeasure(dataProperty, this)) {
            return this.decimalPlacesPercentage;
        }
        return -1;
    }

    public getColumnDisplayUnits(dataProperty: DataProperty): DisplayUnits {
        if (helpers.isAdditionalMeasure(dataProperty)) {
            let units = this.getColumnViewSetting(dataProperty, "displayUnits");
            if (units === EMPTY || units === DisplayUnits.Default) {
                return this.displayUnits;
            }
            return units;
        }
        else if (isRelativeMeasure(dataProperty, this)) {
            return DisplayUnits.Relative;
        }
        return this.displayUnits;
    }

    public getColumnInvert(dataProperty: DataProperty): boolean {
        return this.getColumnSetting(dataProperty, "invert");
    }

    public getColumnTextColor(dataProperty: DataProperty, defaultColor: string): string {
        let color = this.getColumnViewSetting(dataProperty, "textColor")
        if (color === "" || color === null) {
            return defaultColor;
        }
        else {
            return color;
        }
    }

    public getColumnBackgroundFill(dataProperty: DataProperty): string {
        return this.getColumnViewSetting(dataProperty, "backgroundFill");
    }

    public getColumnBorder(dataProperty: DataProperty): string {
        return this.getColumnViewSetting(dataProperty, "border")
    }

    public getCategoryFormatIsBold(categoryName: string): boolean {
        let bold = this.getCategoryFormatSetting(categoryName, "isBold");
        return bold === undefined ? false : bold;
    }

    public getCategoryFormatIsItalic(categoryName: string): boolean {
        let italic = this.getCategoryFormatSetting(categoryName, "isItalic");
        return italic === undefined ? false : italic;
    }

    public getCategoryFormatTextColor(categoryName: string): string {
        return this.getCategoryFormatSetting(categoryName, "textColor");
    }

    public getCategoryFormatHighlightColor(categoryName: string): string {
        return this.getCategoryFormatSetting(categoryName, "highlightColor");
    }

    public getCategoryFormatTopBorder(categoryName: string): boolean {
        let topBorder = this.getCategoryFormatSetting(categoryName, "topBorder");
        return topBorder === undefined ? false : topBorder;
    }

    public getCategoryFormatDisplayUnits(categoryName: string): DisplayUnits {
        return this.getCategoryFormatSetting(categoryName, "numberFormat");
    }

    public getCategoryFormatDecimalPlaces(categoryName: string): number {
        let categoryFormatDecimalPlaces = this.getCategoryFormatSetting(categoryName, "decimalPlaces");
        return (categoryFormatDecimalPlaces !== undefined && categoryFormatDecimalPlaces !== null ? categoryFormatDecimalPlaces : -1);
    }


    public getDataPropertyShowAsTable(dataProperty: DataProperty): ShowAsTableOptions {
        let showAsTable = this.getColumnViewSetting(dataProperty, "showAsTable");
        if (showAsTable === null) {
            return ShowAsTableOptions.NotSet;
        }
        if (helpers.isAdditionalMeasure(dataProperty) && showAsTable === ShowAsTableOptions.NotSet) {
            return ShowAsTableOptions.True;
        }
        return showAsTable;
    }

    public isAbsoluteChartWaterfall(): boolean {
        let showAsTable = this.getDataPropertyShowAsTable(DataProperty.AbsoluteDifference);
        if (this.absoluteChart === AbsoluteChart.Bar || showAsTable === ShowAsTableOptions.True) {
            return false;
        }
        if (this.showAsTable && showAsTable === ShowAsTableOptions.NotSet) {
            return false;
        }
        return true;
    }

    public isValueChartWaterfall(): boolean {
        if (this.valueChart !== ValueChart.Waterfall && this.valueChart !== ValueChart.TwoWaterfalls && this.valueChart !== ValueChart.CalculationWaterfall) {
            return false;
        }
        let showAsTable = this.getDataPropertyShowAsTable(DataProperty.Value);
        let dataPropertiesToCheck = [];
        if (this.viewModel.HasReferenceColumn && !this.isColumnHidden(DataProperty.ReferenceValue)) {
            dataPropertiesToCheck.push(DataProperty.ReferenceValue);
        }
        if (this.viewModel.HasSecondReferenceColumn && !this.isColumnHidden(DataProperty.SecondReferenceValue)) {
            dataPropertiesToCheck.push(DataProperty.SecondReferenceValue);
        }
        if (this.viewModel.HasThirdReferenceColumn && !this.isColumnHidden(DataProperty.ThirdReferenceValue)) {
            dataPropertiesToCheck.push(DataProperty.ThirdReferenceValue);
        }
        if (this.viewModel.HasFourthReferenceColumn && !this.isColumnHidden(DataProperty.FourthReferenceValue)) {
            dataPropertiesToCheck.push(DataProperty.FourthReferenceValue);
        }
        if (this.viewModel.HasFifthReferenceColumn && !this.isColumnHidden(DataProperty.FifthReferenceValue)) {
            dataPropertiesToCheck.push(DataProperty.FifthReferenceValue);
        }
        if (this.viewModel.HasSixthReferenceColumn && !this.isColumnHidden(DataProperty.SixthReferenceValue)) {
            dataPropertiesToCheck.push(DataProperty.SixthReferenceValue);
        }
        if (this.viewModel.HasSeventhReferenceColumn && !this.isColumnHidden(DataProperty.SeventhReferenceValue)) {
            dataPropertiesToCheck.push(DataProperty.SeventhReferenceValue);
        }
        let anyReferenceWaterfalls = dataPropertiesToCheck.some(dp => {
            let showTable = this.getDataPropertyShowAsTable(dp);
            if (showTable !== ShowAsTableOptions.True) {
                return true;
            }
            return false;
        })
        if (showAsTable === ShowAsTableOptions.True && !anyReferenceWaterfalls) {
            return false;
        }
        if (this.showAsTable && showAsTable === ShowAsTableOptions.NotSet) {
            return false;
        }
        return true;
    }

    public getColumnPercentageFormat(dataProperty: DataProperty) {
        return this.getColumnSetting(dataProperty, "percentageFormat");
    }

    public getPercentageFormat(dataProperty: DataProperty) {
        return this.getColumnSetting(dataProperty, "percentageFormat");
    }

    public getSuppressOthers(dataProperty: DataProperty) {
        return this.getColumnSetting(dataProperty, "suppressOthers");
    }

    public getDecimalPlaces(columnFormat: ColumnFormat): number {
        switch (columnFormat) {
            case ColumnFormat.Value:
            case ColumnFormat.AbsoluteDifference:
            case ColumnFormat.Percent:
                return this.decimalPlaces;
            case ColumnFormat.RelativeDifference:
                return this.decimalPlacesPercentage;
        }
    }

    public getColumnTotalLabel(key: string): string {
        let result = this.columnTotalLabels[key];
        if (result === undefined) {
            return TOTAL;
        }
        return result;
    }

    public getAdditionalMeasureChartType(dataProperty: DataProperty): ChartType {
        let columnFormat = this.getColumnFormat(dataProperty);
        switch (columnFormat) {
            case ColumnFormat.Value:
            case ColumnFormat.Percent:
                return ChartType.ValueChart;
            case ColumnFormat.AbsoluteDifference:
                return ChartType.AbsoluteChart;
            case ColumnFormat.RelativeDifference:
                return ChartType.PlusMinusDot;
            default:
                return ChartType.ValueChart;
        }
    }

    public showNegativeValuesInParenthesis(): boolean {
        return this.negativeValuesFormat === NegativeValuesFormat.Parenthesis;
    }

    public dontShowMinorGridlines() {
        if (this.showAsTable) {
            return !this.showGridlinesTable || this.gridlineDensityTable === 0;
        }
        else {
            return !this.showGridlines || this.gridlineDensity === 0;
        }
    }

    public plottingHorizontally(): boolean {
        return this.showAsTable || this.groupsInColumns;
    }

    public getColumnOrder(dataProperty: DataProperty): number {
        return this.getColumnSetting(dataProperty, "order");
    }

    public persistValueChart(dataProperty: DataProperty) {
        let properties: any = {};
        properties.valueChart = this.valueChart;
        this.changeColumnViewSetting(dataProperty, "showAsTable", ShowAsTableOptions.False)
        properties.columnSettings = stringifyMap(this.columnSettings);
        this.columnSettingsString = stringifyMap(this.columnSettings);
        Visual.getInstance().constructViewModelAndUpdate(this);
    }

    public persistAbsoluteChart(dataProperty: DataProperty) {
        let properties: any = {};
        properties.absoluteChart = this.absoluteChart;
        this.changeColumnViewSetting(dataProperty, "showAsTable", ShowAsTableOptions.False)
        properties.columnSettings = stringifyMap(this.columnSettings);
        this.columnSettingsString = stringifyMap(this.columnSettings);
        Visual.getInstance().constructViewModelAndUpdate(this);
    }

    public persistAdditionalMeasureValueCharts(dataProperty: DataProperty, valueChart: ValueChart) {
        this.changeColumnViewSetting(dataProperty, "showAsTable", ShowAsTableOptions.False)
        this.changeColumnViewSetting(dataProperty, "valueChart", valueChart);
        this.persistColumnSettings();
    }

    public persistAdditionalMeasureAbsoluteCharts(dataProperty: DataProperty, absoluteChart: AbsoluteChart) {
        this.changeColumnViewSetting(dataProperty, "showAsTable", ShowAsTableOptions.False)
        this.changeColumnViewSetting(dataProperty, "absoluteChart", absoluteChart);
        this.persistColumnSettings();
    }

    public persistResults(d: DataPoint) {
        this.resultsChange(d);
        Visual.getInstance().constructViewModelAndUpdate(this);
    }

    public persistSkipped(category: string, isSkipped: boolean) {
        this.skippedChange(category, isSkipped);
        // this.columnSettingsString = stringifyMap(this.columnSettings);
        Visual.getInstance().constructViewModelAndUpdate(this);
    }

    public persistFlatResults(d: DataPoint) {
        this.flatResultsChange(d);
        /*        let modifiedProperties = {
                    merge: [{
                        objectName: CATEGORIES_METADATA,
                        properties: {
                            flatResults: helpers.joinElementsIntoString(this.flatResultCategories),
                        },
                        selector: null,
                    }],
                };*/
        // this.host.persistProperties(modifiedProperties);
        // this.columnSettingsString = stringifyMap(this.columnSettings);
        Visual.getInstance().constructViewModelAndUpdate(this);
    }

    public persistInvertedCategory(d: DataPoint) {
        this.invertedCategoryChange(d);
        /*        let modifiedProperties = {
                    merge: [{
                        objectName: CATEGORIES_METADATA,
                        properties: {
                            inverted: helpers.joinElementsIntoString(this.invertedCategories),
                            userOverrides: helpers.joinElementsIntoString(this.userOverrideCategories),
                        },
                        selector: null,
                    }],
                };*/
        // this.host.persistProperties(modifiedProperties);
        // this.columnSettingsString = stringifyMap(this.columnSettings);
        Visual.getInstance().constructViewModelAndUpdate(this);
    }

    public persistInvertedTotalCategory(d: DataPoint) {
        if (!d.isTotal() && !d.isOtherTotal()) {
            return;
        }
        this.invertedTotalCategoryChange(d);
        Visual.getInstance().constructViewModelAndUpdate(this);
    }

    public persistInvertedGroup(g: ChartGroup) {
        this.invertedGroupChange(g);
        Visual.getInstance().constructViewModelAndUpdate(this);
    }

    public persistCollapsed(d: DataPoint) {
        this.collapsedChange(d);
        this.persistCollapsedCategoriesAndUserDefinedCollapse();
        Visual.getInstance().constructViewModelAndUpdate(this);
    }

    public persistRemovedColumns() {
        this.persistColumnSettings();
        Visual.getInstance().constructViewModelAndUpdate(this);
    }

    public persistColumnsHiddenFromGroups() {
        this.persistColumnSettings();
        Visual.getInstance().constructViewModelAndUpdate(this);
    }

    public persistGroupsCollapsed() {
        /*        let modifiedProperties = {
                    merge: [{
                        objectName: CHART_SETTINGS,
                        properties: {
                            groupsCollapsed: helpers.joinElementsIntoString(this.groupsCollapsed),
                        },
                        selector: null,
                    }],
                };*/
    }

    private persistCollapsedCategories() {
        /*        let modifiedProperties = {
                    merge: [{
                        objectName: CATEGORIES_METADATA,
                        properties: {
                            collapsed: helpers.joinElementsIntoString(this.collapsedCategories),
                        },
                        selector: null,
                    }],
                };*/
    }

    public persistSorting() {
        /*        let sortProperties: VisualObjectInstancesToPersist = {
                    replace: [{
                        objectName: SORT_SETTINGS,
                        properties: {
                            columnName: this.sortColumnName,
                            chartSort: this.chartSort,
                            categorySort: this.categorySort,
                            sortReferenceChart: this.sortReferenceChart,
                        },
                        selector: null,
                    }],
                };*/
    }

    public persistDataLabels() {
        this.integratedDifferenceLabel = (this.integratedDifferenceLabel + 2) % 3;
        /*        let modifiedProperties: VisualObjectInstancesToPersist = {
                    merge: [{
                        objectName: DATA_LABEL_SETTINGS,
                        properties: {
                            integratedDifferenceLabel: this.integratedDifferenceLabel,
                        },
                        selector: null,
                    }],
                };*/
        (<any>window).animateLabels = true;
        // this.host.persistProperties(modifiedProperties);
    }

    public persistColumnOrder() {
        this.persistColumnSettings();
    }

    public clearColumnOrder() {
    }

    public persistColumnFormatChange(dataProperty: DataProperty, selectedColumnFormat: ColumnFormat) {
        this.changeColumnSettingAndPersist(dataProperty, "format", selectedColumnFormat);
    }

    public changeSuppressOthersAndPersist(dataProperty: DataProperty, suppressOthers: boolean) {
        this.changeColumnSettingAndPersist(dataProperty, "suppressOthers", suppressOthers);
    }

    public persistCalculationChange(dataProperty: DataProperty, valueScenario: Scenario, referenceScenario: Scenario, valuePosition: number, referencePosition: number) {
        if (helpersVisual.isFirstComparison(dataProperty)) {
            this.calculations[0] = this.getVarianceColumnName(valueScenario, referenceScenario, DataProperty.AbsoluteDifference);
        }
        else if (helpersVisual.isSecondComparison(dataProperty)) {
            this.calculations[1] = this.getVarianceColumnName(valueScenario, referenceScenario, DataProperty.SecondAbsoluteDifference);
        }
        else if (helpersVisual.isThirdComparison(dataProperty)) {
            this.calculations[2] = this.getVarianceColumnName(valueScenario, referenceScenario, DataProperty.ThirdAbsoluteDifference);
        }
        else if (helpersVisual.isFourthComparison(dataProperty)) {
            this.calculations[3] = this.getVarianceColumnName(valueScenario, referenceScenario, DataProperty.FourthAbsoluteDifference);
        }
        else if (helpersVisual.isFifthComparison(dataProperty)) {
            this.calculations[4] = this.getVarianceColumnName(valueScenario, referenceScenario, DataProperty.FifthAbsoluteDifference);
        }
        else if (helpersVisual.isSixthComparison(dataProperty)) {
            this.calculations[5] = this.getVarianceColumnName(valueScenario, referenceScenario, DataProperty.SixthAbsoluteDifference);
        }
        else if (helpersVisual.isSeventhComparison(dataProperty)) {
            this.calculations[6] = this.getVarianceColumnName(valueScenario, referenceScenario, DataProperty.SeventhAbsoluteDifference);
        }

        let properties: any = {};
        this.copyColumnSettings(dataProperty, valueScenario, referenceScenario, properties);
        properties.columnSettings = stringifyMap(this.columnSettings);
        properties.calculations = JSON.stringify(this.calculations);
        /*        let modifiedProperties = {
                    merge: [{
                        objectName: CHART_SETTINGS,
                        properties: properties,
                        selector: null,
                    }],
                };*/
        this.columnSettingsString = properties.columnSettings;
        this.calculationsString = properties.calculations;
        Visual.getInstance().constructViewModelAndUpdate(this);
    }

    // When a users changes the calculation of the column we wish to copy the column's setting. This preservers
    // all the column formatting, column order,... and makes for a better user experience.
    private copyColumnSettings(dataProperty: DataProperty, valueScenario: Scenario, referenceScenario: Scenario, properties: any) {
        let currentName = this.getColumnName(dataProperty);
        let newCalculationName = this.getVarianceColumnName(valueScenario, referenceScenario, dataProperty);
        this.columnSettings.set(newCalculationName, this.columnSettings.get(currentName));
        if (this.sortColumnName === currentName) {
            properties.sortColumnName = newCalculationName;
        }
        // Variances always come in pairs: AbsoluteDifference and RealtiveDifference, SecondAbsoluteDifference and SecondRelative difference...
        // We want to copy the settins on both since the change in the calculation also changes both.
        let otherVarianceCalculation = this.getOtherVarianceDataProperty(dataProperty);
        currentName = this.getColumnName(otherVarianceCalculation);
        newCalculationName = this.getVarianceColumnName(valueScenario, referenceScenario, otherVarianceCalculation);
        this.columnSettings.set(newCalculationName, this.columnSettings.get(currentName));
    }

    private getOtherVarianceDataProperty(dataProperty: DataProperty): DataProperty {
        switch (dataProperty) {
            case DataProperty.AbsoluteDifference:
                return DataProperty.RelativeDifference;
            case DataProperty.SecondAbsoluteDifference:
                return DataProperty.SecondRelativeDifference;
            case DataProperty.ThirdAbsoluteDifference:
                return DataProperty.ThirdRelativeDifference;
            case DataProperty.FourthAbsoluteDifference:
                return DataProperty.FourthRelativeDifference;
            case DataProperty.FifthAbsoluteDifference:
                return DataProperty.FifthRelativeDifference;
            case DataProperty.SixthAbsoluteDifference:
                return DataProperty.SixthRelativeDifference;
            case DataProperty.SeventhAbsoluteDifference:
                return DataProperty.SeventhRelativeDifference;
            case DataProperty.RelativeDifference:
                return DataProperty.AbsoluteDifference;
            case DataProperty.SecondRelativeDifference:
                return DataProperty.SecondAbsoluteDifference;
            case DataProperty.ThirdRelativeDifference:
                return DataProperty.ThirdAbsoluteDifference;
            case DataProperty.FourthRelativeDifference:
                return DataProperty.FourthAbsoluteDifference;
            case DataProperty.FifthRelativeDifference:
                return DataProperty.FifthAbsoluteDifference;
            case DataProperty.SixthRelativeDifference:
                return DataProperty.SixthAbsoluteDifference;
            case DataProperty.SeventhRelativeDifference:
                return DataProperty.SeventhAbsoluteDifference;
        }
    }

    public persistMarkerStyleChange(dataProperty: DataProperty, selectedMarkerStyle: MarkerStyle) {
        this.changeColumnViewSettingAndPersist(dataProperty, "markerStyle", selectedMarkerStyle);
    }

    public toggleAndPersistColumnBold(dataProperty: DataProperty) {
        let currentColumnSetting = this.getColumnSettings(dataProperty);
        let value = this.showAsTable ? currentColumnSetting.tableView.bold : currentColumnSetting.chartView.bold;
        this.changeColumnViewSettingAndPersist(dataProperty, "bold", !value);
    }

    public persistColumnDecimalPlaces(dataProperty: DataProperty, places: number) {
        this.changeColumnViewSettingAndPersist(dataProperty, "decimalPlaces", places);
    }

    public persistColumnDisplayUnits(dataProperty: DataProperty, units: string) {
        this.changeColumnViewSettingAndPersist(dataProperty, "displayUnits", units);
    }

    public persistColumnInvert(dataProperty: DataProperty) {
        let currentColumnSetting = this.getColumnSettings(dataProperty);
        currentColumnSetting.invert = !currentColumnSetting.invert;
        this.persistColumnSettings();
    }

    public persistTextColor(dataProperty: DataProperty, color: any) {
        this.changeColumnViewSettingAndPersist(dataProperty, "textColor", this.getColorString(color));
    }

    public persistBackgroundFill(dataProperty: DataProperty, color: any) {
        this.changeColumnViewSettingAndPersist(dataProperty, "backgroundFill", this.getColorString(color));
    }

    public persistBorder(dataProperty: DataProperty, color: any) {
        this.changeColumnViewSettingAndPersist(dataProperty, "border", this.getColorString(color));
    }

    public persistShowDataPropertyAsTable(dataProperty: DataProperty, showAsTable: ShowAsTableOptions) {
        this.changeColumnViewSettingAndPersist(dataProperty, "showAsTable", showAsTable);
    }

    public persistDesignProperty(properties: any) {
        /*        let modifiedProperties = {
                    merge: [{
                        objectName: CHART_SETTINGS,
                        properties: properties,
                        selector: null,
                    }],
                };*/
        // this.host.persistProperties(modifiedProperties);
    }

    public getColorString(color: any) {
        if (color === null) {
            return "";
        }
        else {
            return color.toHEXA().toString();
        }
    }

    public persistHeader(dataProperty: DataProperty, value: string) {
        if (helpers.isAdditionalMeasure(dataProperty)) {
            let settings = <AdditionalMeasureColumnSettings>this.getColumnSettings(dataProperty);
            settings.header = value;
            this.persistColumnSettings();
        }
        else {
            let columnName = this.getColumnName(dataProperty);
            let properties: any = {};
            properties[columnName] = value;
            /*            let modifiedProperties: VisualObjectInstancesToPersist = {
                            merge: [{
                                objectName: LEGEND_HEADER_SETTINGS,
                                properties: properties,
                                selector: null,
                            }],
                        };*/
            this[columnName] = value;
            // this.host.persistProperties(modifiedProperties);
        }
    }

    public persistGroupName(chartGroup: ChartGroup, newName: string) {
        if (chartGroup.group === newName) {
            delete this.groupNames[chartGroup.fullGroup];
        }
        else {
            this.groupNames[chartGroup.fullGroup] = newName;
        }
        this.groupNamesString = helpersVisual.stringifyMap(this.groupNames);
    }

    public persistScaleGroup(dataProperty: DataProperty, group: number) {
        this.changeColumnSetting(dataProperty, "scaleGroup", group);
        this.persistColumnSettings();
    }

    public persistTitle(value: string) {
        let properties: any = {};
        properties.text = value;
        this.titleText = value;
        Visual.getInstance().constructViewModelAndUpdate(this);
    }

    public persistProFeaturesEnabled(value: boolean) {
        let properties: any = {};
        properties.show = value;
        /*        let modifiedProperties: VisualObjectInstancesToPersist = {
                    merge: [{
                        objectName: PRO_FEATURES_SETTINGS,
                        properties: properties,
                        selector: null,
                    }],
                };*/
        // this.host.persistProperties(modifiedProperties);
    }

    public persistProFeatures(enabled: boolean, unlocked: boolean, disabledByUser: boolean) {
        let properties: any = {};
        properties.show = enabled;
        properties.proFeaturesUnlocked = unlocked;
        properties.proFeaturesDisabledByUser = disabledByUser;
        /*        let modifiedProperties: VisualObjectInstancesToPersist = {
                    merge: [{
                        objectName: PRO_FEATURES_SETTINGS,
                        properties: properties,
                        selector: null,
                    }],
                };*/
        // this.host.persistProperties(modifiedProperties);
    }

    public persistLicenseKey(value: string) {
        let properties: any = {};
        properties.licenseKey = value;
        /*        let modifiedProperties: VisualObjectInstancesToPersist = {
                    merge: [{
                        objectName: LICENSE_SETTINGS,
                        properties: properties,
                        selector: null,
                    }],
                };*/
        // this.host.persistProperties(modifiedProperties);
    }

    public persistLastLicenseCheck(value: string) {
        let properties: any = {};
        properties.lastLicenseCheck = value;
        /*        let modifiedProperties: VisualObjectInstancesToPersist = {
                    merge: [{
                        objectName: LICENSE_SETTINGS,
                        properties: properties,
                        selector: null,
                    }],
                };*/
        // this.host.persistProperties(modifiedProperties);
    }

    public persistShowColumnTotals() {
        let properties: any = {};
        properties.showColumnTotals = this.showColumnTotals;
        /*        let modifiedProperties: VisualObjectInstancesToPersist = {
                    merge: [{
                        objectName: CHART_SETTINGS,
                        properties: properties,
                        selector: null,
                    }],
                };*/
        // this.host.persistProperties(modifiedProperties);
    }

    public persistColumnTotalLabels(key: string, value: string) {
        let properties: any = {};
        this.columnTotalLabels[key] = value;
        properties.columnTotalLabels = JSON.stringify(this.columnTotalLabels);
        /*        let modifiedProperties: VisualObjectInstancesToPersist = {
                    merge: [{
                        objectName: CHART_SETTINGS,
                        properties: properties,
                        selector: null,
                    }],
                };*/
        // this.host.persistProperties(modifiedProperties);
    }

    public persistShowColumnGrandTotal() {
        let properties: any = {};
        properties.showColumnGrandTotal = this.showColumnGrandTotal;
        /*        let modifiedProperties: VisualObjectInstancesToPersist = {
                    merge: [{
                        objectName: CHART_SETTINGS,
                        properties: properties,
                        selector: null,
                    }],
                };*/
        // this.host.persistProperties(modifiedProperties);
        Visual.getInstance().constructViewModelAndUpdate(this);
    }

    public persistColumnTotalBorderColor(level: number, color: string) {
        if (this.showAsTable) {
            let columnEmphasize = this.columnTotalEmphasizeTable.get(level.toString());
            if (columnEmphasize === undefined) {
                columnEmphasize = { backgroundColor: "", borderColor: this.getColorString(color) }
            }
            else {
                columnEmphasize.borderColor = this.getColorString(color);
            }
            this.columnTotalEmphasizeTable.set(level.toString(), columnEmphasize);
            this.columnTotalEmphasizeTableString = helpersVisual.stringifyMap(this.columnTotalEmphasizeTable);
        }
        else {
            let columnEmphasize = this.columnTotalEmphasize.get(level.toString());
            if (columnEmphasize === undefined) {
                columnEmphasize = { backgroundColor: "", borderColor: this.getColorString(color) }
            }
            else {
                columnEmphasize.borderColor = this.getColorString(color);
            }
            this.columnTotalEmphasize.set(level.toString(), columnEmphasize);
            this.columnTotalEmphasizeString = helpersVisual.stringifyMap(this.columnTotalEmphasize);
        }
        Visual.getInstance().constructViewModelAndUpdate(this);
    }

    public persistColumnTotalBackgroundColor(level: number, color: string) {
        if (this.showAsTable) {
            let columnEmphasize = this.columnTotalEmphasizeTable.get(level.toString());
            if (columnEmphasize === undefined) {
                columnEmphasize = { backgroundColor: this.getColorString(color), borderColor: "" }
            }
            else {
                columnEmphasize.backgroundColor = this.getColorString(color);
            }
            this.columnTotalEmphasizeTable.set(level.toString(), columnEmphasize);
            this.columnTotalEmphasizeTableString = helpersVisual.stringifyMap(this.columnTotalEmphasizeTable);
        }
        else {
            let columnEmphasize = this.columnTotalEmphasize.get(level.toString());
            if (columnEmphasize === undefined) {
                columnEmphasize = { backgroundColor: this.getColorString(color), borderColor: "" }
            }
            else {
                columnEmphasize.backgroundColor = this.getColorString(color);
            }
            this.columnTotalEmphasize.set(level.toString(), columnEmphasize);
            this.columnTotalEmphasizeString = helpersVisual.stringifyMap(this.columnTotalEmphasize);
        }
        Visual.getInstance().constructViewModelAndUpdate(this);
    }

    public persistColumnGrandTotalLabel(value: string) {
        this.columnGrandTotalLabel = value;
    }

    public persistAddedFormulas() {
        let properties: any = {};
        properties.addedFormulas = helpers.joinElementsIntoString(this.formulaCalculation.formulas);
        /*        let modifiedProperties: VisualObjectInstancesToPersist = {
                    merge: [{
                        objectName: CATEGORIES_METADATA,
                        properties: properties,
                        selector: null,
                    }],
                };*/
        // this.host.persistProperties(modifiedProperties);
        Visual.getInstance().constructViewModelAndUpdate(this);
    }

    public addFormula(formulaData: Formula, editing: boolean) {
        if (!editing) {
            this.formulaCalculation.formulas.push(formulaData);
        }
        this.persistAddedFormulas();
        if (!editing) {
            this.persistSkipped(formulaData.identity, false);
        }
    }

    public deleteFormula(fullCategory: string, positionOfDeleted: string) {
        // Go over all formulas and for those whose position is set as the formula getting deleted, change their position
        let formulas = this.formulaCalculation.formulas.filter(f => f.identity !== fullCategory);
        formulas.forEach(f => {
            if (f.position == fullCategory) {
                f.position = positionOfDeleted;
            }
        });
        this.formulaCalculation.formulas = formulas;
        this.persistAddedFormulas();
    }

    public deleteFormulas(formulas: Formula[]) {
        let remainingFormulas = this.formulaCalculation.formulas.filter(f => formulas.indexOf(f) < 0);
        remainingFormulas.forEach(rf => {
            formulas.forEach(df => {
                // this third loop is perhaps not optimal, but makes sure positions are correctly propagated in case of deleting multiple formulas in an unusual order.
                formulas.forEach(() => {
                    if (rf.position == df.identity) {
                        rf.position = df.position;
                    }
                })
            });
        });
        this.formulaCalculation.formulas = remainingFormulas;
        this.persistAddedFormulas();
    }

    public switchTableOption() {
        if (licensingOffice.proFeaturesUnlocked()) {
            this.showAsTable = !this.showAsTable;
            this.showAdSlide = false;
        } else {
            if (this.showAdSlide) {
                this.showAdSlide = false;
                this.showAsTable = true;
            } else if (this.showAsTable) {
                this.showAsTable = false;
            } else {
                this.showAdSlide = true;
            }
        }
    }

    public getHeader(dataProperty: DataProperty) {
        if (helpers.isAdditionalMeasure(dataProperty)) {
            let settings = <AdditionalMeasureColumnSettings>this.getColumnSettings(dataProperty);
            return settings.header;
        }
        else {
            let name = this.getColumnName(dataProperty);
            return this.getSettingFromColumnName(name);
        }
    }
    public setHeader(name: string, result: string) {
        this[name] = result;
    }

    public getSettingFromColumnName(name: string) {
        return this[name];
    }

    public shouldHideDataLabelUnits(): boolean {
        return this.displayUnits !== DisplayUnits.Auto && this.displayUnits !== DisplayUnits.None && this.showUnits !== DataLabelUnitOptions.DataLabels;
    }

    public shouldShowUnitsSetting(): boolean {
        return this.displayUnits !== DisplayUnits.Auto && this.displayUnits !== DisplayUnits.None;
    }

    public shouldShowUnitsInTitle(): boolean {
        return this.displayUnits !== DisplayUnits.Auto && this.displayUnits !== DisplayUnits.None && this.showUnits === DataLabelUnitOptions.Title;
    }

    public expandAll(level: number): void {
        let hierarchies = this.viewModel.chartGroups.getAllHierarchiesByLevel(level);
        let categories = hierarchies.map(h => h.totalDataPoint.fullCategory);
        let categoriesToRemove = categories.filter(c => this.collapsedCategories.indexOf(c) > -1);
        this.collapsedCategories = this.collapsedCategories.filter(c => categoriesToRemove.indexOf(c) === -1);
        this.persistCollapsedCategoriesAndUserDefinedCollapse();
    }

    public collapseAll(fullCategory: string, level: number): void {
        let hierarchies = this.viewModel.chartGroups.getAllHierarchiesByLevel(level);
        let categories = hierarchies.map(h => h.totalDataPoint.fullCategory);
        let categoriesToAdd = categories.filter(c => this.collapsedCategories.indexOf(c) === -1);
        categoriesToAdd.map(c => this.collapsedCategories.push(c));
        (<any>window).collapseScroll = true;
        (<any>window).collapseCategory = fullCategory;
        (<any>window).currentScrollTop = 0;
        this.persistCollapsedCategoriesAndUserDefinedCollapse();
    }

    public expandEntireGroup(level: number): void {
        let groups = this.viewModel.chartGroups.getAllGroupsByLevel(level);
        groups = groups.filter(g => !g.isSubtotal);
        let fullGroups = groups.map(h => h.fullGroup);
        let groupsToRemove = fullGroups.filter(g => this.groupsCollapsed.indexOf(g) > -1);
        this.groupsCollapsed = this.groupsCollapsed.filter(g => groupsToRemove.indexOf(g) === -1);
        this.persistGroupsCollapsed();
    }

    public collapseEntireGroup(level: number): void {
        let groups = this.viewModel.chartGroups.getAllGroupsByLevel(level);
        groups = groups.filter(g => !g.isSubtotal);
        let fullGroups = groups.map(h => h.fullGroup);
        let groupsToAdd = fullGroups.filter(g => this.groupsCollapsed.indexOf(g) === -1);
        groupsToAdd.map(g => this.groupsCollapsed.push(g));
        this.persistGroupsCollapsed();
    }

    public collapseByDefault(): boolean {
        if (!this.userChangedExpandCollapse && this.viewModel.numberOfLevels > 2) {
            let categoriesToAdd: string[] = [];
            for (let i = 1; i < this.viewModel.numberOfLevels; i++) {
                let hierarchies = this.viewModel.chartGroups.getAllHierarchiesByLevel(i);
                let categories = hierarchies.map(h => h.totalDataPoint.fullCategory);
                categories.filter(c => this.collapsedCategories.indexOf(c) === -1).map(c => categoriesToAdd.push(c));
            }
            if (categoriesToAdd.length === 0) {
                return false;
            }
            else {
                categoriesToAdd.map(c => this.collapsedCategories.push(c));
                this.persistCollapsedCategories();
                return true;
            }
        }
    }

    private persistCollapsedCategoriesAndUserDefinedCollapse() {
        /*        let modifiedProperties = {
                    merge: [{
                        objectName: CATEGORIES_METADATA,
                        properties: {
                            collapsed: helpers.joinElementsIntoString(this.collapsedCategories),
                            userChangedExpandCollapse: true,
                        },
                        selector: null,
                    }],
                };*/
        // this.host.persistProperties(modifiedProperties);
        Visual.getInstance().constructViewModelAndUpdate(this);
    }

    public showFrozenTitle(): boolean {
        return this.freezeTitle;
    }

    public showNonFrozenTitle(): boolean {
        return this.showTitle && !this.freezeTitle;
    }

    public showFrozenRowGrandTotal(): boolean {
        return this.showRowGrandTotal && this.freezeGrandTotal;
    }

    public showFrozenCategories(): boolean {
        return this.showCategories && this.freezeCategories;
    }

    public showUnfrozenCategories(): boolean {
        return this.showCategories && !this.freezeCategories;
    }

    public showFrozenGTAndCategories(): boolean {
        return this.showFrozenCategories() && this.showFrozenRowGrandTotal();
    }

    public showUnfrozenRowGrandTotal(): boolean {
        return this.showRowGrandTotal && !this.freezeGrandTotal;
    }

    public multipleCharts() {
        let numberOfGroups = this.viewModel.numberOfLastLevelChartGroups;
        if (this.viewModel.settings.plottingHorizontally()) {
            return numberOfGroups > 1;
        }
        else {
            if (this.viewModel.settings.showFrozenRowGrandTotal()) {
                return numberOfGroups > 2;
            }
            else {
                return numberOfGroups > 1;
            }
        }
    }

    public shouldShowScenarioSortHeaders(plotter: Plotter): boolean {
        return (this.showAsTable || this.viewModel.HasAdditionalMeasures || this.viewModel.HasSecondReferenceColumn || this.groupsInColumns)
            && plotter.multipleChartPlotDefinitions();
    }

    public shouldShowSlider(): boolean {
        return this.getRealInteractionSettingValue(this.allowSliderChange);
    }

    public shouldUseItalicText(dataProperty: DataProperty): boolean {
        if (dataProperty === DataProperty.RelativeDifference || dataProperty === DataProperty.SecondRelativeDifference || dataProperty === DataProperty.ThirdRelativeDifference ||
            dataProperty === DataProperty.FourthRelativeDifference || dataProperty === DataProperty.FifthRelativeDifference || dataProperty === DataProperty.SixthRelativeDifference || dataProperty === DataProperty.SeventhRelativeDifference) {
            return true;
        }
        return helpers.isAdditionalMeasure(dataProperty) && this.getColumnSetting(dataProperty, "format") === ColumnFormat.RelativeDifference;
    }

    public shouldDrawColumnRect(plotter: Plotter) {
        return (this.shouldShowScenarioSortHeaders(plotter) || !this.plottingHorizontally() || this.viewModel.numberOfLastLevelChartGroups === 1);
    }

    public shouldPlotVerticalAxis(chartHierarchy: ChartHierarchy) {
        return !((chartHierarchy.hasChildHierarchies || chartHierarchy.isCollapsed()) && this.showTotals === ShowTotals.AboveHideValues) && !chartHierarchy.anyDataPointPlotted();
    }

    public shouldDrawColumnBorders(plotter: Plotter) {
        return (this.shouldShowScenarioSortHeaders(plotter) || !this.plottingHorizontally() || this.viewModel.numberOfLastLevelChartGroups === 1);
    }

    public shouldDrawBorders(plotter: Plotter, dataProperty: DataProperty) {
        return (this.shouldShowScenarioSortHeaders(plotter) || !this.plottingHorizontally() || this.viewModel.numberOfLastLevelChartGroups === 1) && this.getColumnBorder(dataProperty) !== "";
    }

    public getColumnTotalBackgroundColor(level: number) {
        let columnTotalFormat = this.showAsTable ? this.columnTotalEmphasizeTable.get(level.toString()) : this.columnTotalEmphasize.get(level.toString());
        if (columnTotalFormat !== undefined) {
            return columnTotalFormat.backgroundColor;
        }
        else {
            return "";
        }
    }

    public getColumnTotalBorderColor(level: number) {
        let columnTotalFormat = this.showAsTable ? this.columnTotalEmphasizeTable.get(level.toString()) : this.columnTotalEmphasize.get(level.toString());
        if (columnTotalFormat !== undefined)
            return columnTotalFormat.borderColor;
        else {
            return "";
        }
    }

    public getHeadersForCurrentGroup(currentHeader: Header) {
        return this.headers.filter(h => h.groupIndex === currentHeader.groupIndex);
    }

    public shouldDrawColumnAdder(fullDataProperties: DataProperty[], numberOfGroupLevels: number): boolean {
        if (!this.proVersionActive()) {
            return false;
        }
        if (this.plottingHorizontally()) {
            if (numberOfGroupLevels > 1 && !this.showColumnTotals) {
                return true;
            }
            if (numberOfGroupLevels >= 1 && !this.showColumnGrandTotal) {
                return true;
            }
        }
        if (this.chartType !== ACT_ABS_REL) {
            return false;
        }
        let columnRemoved = fullDataProperties.some(dp => this.getColumnViewSetting(dp, "hidden"));
        let columnRemovedFromGroup = fullDataProperties.some(dp => this.getColumnViewSetting(dp, "hiddenFromGroups").length > 0);
        return columnRemoved || (columnRemovedFromGroup && this.plottingHorizontally());
    }


    public getReferenceScenarioForDataProperty(dataProperty: DataProperty): Scenario {
        if (helpersVisual.isFirstComparison(dataProperty)) {
            return this.referenceScenario;
        }
        else if (helpersVisual.isSecondComparison(dataProperty)) {
            return this.secondReferenceScenario;
        }
        else if (helpersVisual.isThirdComparison(dataProperty)) {
            return this.thirdReferenceScenario;
        }
        else if (helpersVisual.isFourthComparison(dataProperty)) {
            return this.fourthReferenceScenario;
        }
        else if (helpersVisual.isFifthComparison(dataProperty)) {
            return this.fifthReferenceScenario;
        }
        else if (helpersVisual.isSixthComparison(dataProperty)) {
            return this.sixthReferenceScenario;
        }
        else if (helpersVisual.isSeventhComparison(dataProperty)) {
            return this.seventhReferenceScenario;
        }
        return null;
    }

    public getValueScenarioForDataProperty(dataProperty: DataProperty): Scenario {
        if (helpersVisual.isFirstComparison(dataProperty)) {
            return this.valueScenario;
        }
        else if (helpersVisual.isSecondComparison(dataProperty)) {
            return this.secondValueScenario;
        }
        else if (helpersVisual.isThirdComparison(dataProperty)) {
            return this.thirdValueScenario;
        }
        else if (helpersVisual.isFourthComparison(dataProperty)) {
            return this.fourthValueScenario;
        }
        else if (helpersVisual.isFifthComparison(dataProperty)) {
            return this.fifthValueScenario;
        }
        else if (helpersVisual.isSixthComparison(dataProperty)) {
            return this.sixthValueScenario;
        }
        else if (helpersVisual.isSeventhComparison(dataProperty)) {
            return this.seventhValueScenario;
        }
        else if (helpers.isAdditionalMeasure(dataProperty)) {
            return Scenario.Actual;
        }
        return null;
    }

    public shouldShowDataPropertyAsTable(dataProperty: DataProperty): boolean {
        let showDataPropertyAsTable = this.getDataPropertyShowAsTable(dataProperty);
        return this.showAsTable && showDataPropertyAsTable !== ShowAsTableOptions.False || showDataPropertyAsTable === ShowAsTableOptions.True;
    }

    public proVersionActive(): boolean {
        return this.proFeaturesEnabled && this.proFeaturesUnlocked;
    }

    public shouldShowTopN() {
        return this.topNType !== TopNType.Off;
    }

    public getDisplayType(dataProperty: DataProperty, defaultValue: ChartType): ChartType {
        let showDataPropertyAsTable = this.getDataPropertyShowAsTable(dataProperty);
        if (showDataPropertyAsTable === ShowAsTableOptions.NotSet) {
            return defaultValue;
        }
        else if (showDataPropertyAsTable === ShowAsTableOptions.True) {
            return ChartType.Table;
        }
        else {
            switch (dataProperty) {
                case DataProperty.Value:
                case DataProperty.ReferenceValue:
                case DataProperty.SecondReferenceValue:
                case DataProperty.ThirdReferenceValue:
                case DataProperty.FourthReferenceValue:
                case DataProperty.FifthReferenceValue:
                case DataProperty.SixthReferenceValue:
                case DataProperty.SeventhReferenceValue:
                    if ([DataProperty.ReferenceValue, DataProperty.SecondReferenceValue].indexOf(dataProperty) > -1 && this.valueChart === ValueChart.OverlappedBar) {
                        return ChartType.Table;
                    }
                    return ChartType.ValueChart;
                case DataProperty.AbsoluteDifference:
                case DataProperty.SecondAbsoluteDifference:
                case DataProperty.ThirdAbsoluteDifference:
                case DataProperty.FourthAbsoluteDifference:
                case DataProperty.FifthAbsoluteDifference:
                case DataProperty.SixthAbsoluteDifference:
                case DataProperty.SeventhAbsoluteDifference:
                    return ChartType.AbsoluteChart;
                case DataProperty.RelativeDifference:
                case DataProperty.SecondRelativeDifference:
                case DataProperty.ThirdRelativeDifference:
                case DataProperty.FourthRelativeDifference:
                case DataProperty.FifthRelativeDifference:
                case DataProperty.SixthRelativeDifference:
                case DataProperty.SeventhRelativeDifference:
                    return ChartType.PlusMinusDot;
                case DataProperty.AdditionalMeasure1:
                case DataProperty.AdditionalMeasure2:
                case DataProperty.AdditionalMeasure3:
                case DataProperty.AdditionalMeasure4:
                case DataProperty.AdditionalMeasure5:
                case DataProperty.AdditionalMeasure6:
                case DataProperty.AdditionalMeasure7:
                case DataProperty.AdditionalMeasure8:
                case DataProperty.AdditionalMeasure9:
                case DataProperty.AdditionalMeasure10:
                case DataProperty.AdditionalMeasure11:
                case DataProperty.AdditionalMeasure12:
                case DataProperty.AdditionalMeasure13:
                case DataProperty.AdditionalMeasure14:
                case DataProperty.AdditionalMeasure15:
                case DataProperty.AdditionalMeasure16:
                case DataProperty.AdditionalMeasure17:
                case DataProperty.AdditionalMeasure18:
                case DataProperty.AdditionalMeasure19:
                case DataProperty.AdditionalMeasure20:
                    return this.getAdditionalMeasureChartType(dataProperty);
            }
        }
    }

    public dataPropertyInRemovedColumns(dataProperty: DataProperty) {
        let settings = this.getColumnSettings(dataProperty);
        if (this.showAsTable) {
            return settings?.tableView.hidden;
        } else {
            return settings?.chartView.hidden;
        }
    }

    public getHiddenColumns(): DataProperty[] {
        let result: DataProperty[] = [];
        if (this.showAsTable) {
            this.columnSettings.forEach((setting, key) => {
                if (setting.tableView.hidden) {
                    result.push(this.getDataPropertyFromColumnSettings(setting));
                }
            });
        }
        else {
            this.columnSettings.forEach((setting, key) => {
                if (setting.chartView.hidden) {
                    result.push(this.getDataPropertyFromColumnSettings(setting));
                }
            });
        }
        return result;
    }

    public persistColumnAdderFirstTime() {
        this.firstTimeShowingColumnAdder = false;
        Visual.getInstance().constructViewModelAndUpdate(this);
    }

    public shouldSkipGroup(chartGroup: ChartGroup): boolean {
        if (chartGroup.isColumnGrandTotal) {
            if (this.showColumnGrandTotal) {
                return false;
            }
            return true;
        }
        if (chartGroup.isSubtotal && !this.showColumnTotals) {
            let parent = <ChartGroup>chartGroup.parent;
            // Figure out if parent is collapsed and not parents further up are collapsed.
            // In this case we show the total. In all other cases we don't.
            let isParentCollapsed = parent && (<ChartGroup>parent).isCollapsed;
            if (!isParentCollapsed) {
                return true;
            }
            parent = <ChartGroup>parent.parent;
            while (parent) {
                if ((<ChartGroup>parent).isCollapsed) {
                    return true;
                }
                parent = <ChartGroup>parent.parent;
            }
            return false;
        }
        if (this.isGroupOrParentCollapsed(chartGroup) && !chartGroup.isSubtotal) {
            return true;
        }
        if (chartGroup.isSubtotal && this.hasCollapsedAncestor(chartGroup)) {
            return true;
        }
        return false;
    }

    public shouldUseBoldGroupHeaders(chartGroup: ChartGroup, maximumExpandedGroupLevel: number): boolean {
        if (chartGroup.isColumnGrandTotal) {
            return true;
        }
        if (chartGroup.isSubtotal && !chartGroup.isCollapsed) {
            return chartGroup.level <= maximumExpandedGroupLevel;
        }
        else {
            return false;
        }
    }

    public getCategoryHighlightColor(category: string): string {
        let customCategoryColor = this.getCategoryFormatHighlightColor(category);
        return customCategoryColor ? customCategoryColor : this.colorScheme.neutralColor;
    }

    public getGapBetweenColumns(): number {
        return this.gapBetweenColumnsPercent / 100;
    }

    public getScaleGroup(dataProperty: DataProperty): number {
        return this.getColumnSetting(dataProperty, "scaleGroup");
    }

    public getAdditionalMeasureAbsoluteChart(dataProperty: DataProperty): AbsoluteChart {
        return this.getColumnViewSetting(dataProperty, "absoluteChart")
    }

    public getAdditionalMeasureValueChart(dataProperty: DataProperty): ValueChart {
        return this.getColumnViewSetting(dataProperty, "valueChart")
    }

    public getGroupName(chartGroup: ChartGroup): string {
        let storedName = this.groupNames[chartGroup.fullGroup];
        return storedName ? storedName : chartGroup.group;
    }

    public shouldDrawLastConnectingLine() {
        return this.viewModel.numberOfLevels === 1 && this.showRowGrandTotal && (this.viewModel.numberOfGroupLevels >= 1 ? this.groupsInColumns : true);
    }

    public anyAnalyticsLinesEnabled() {
        return this.showAverageLine || this.showMedianLine || this.showPercentileLine || this.showConstantLine;
    }

    public getEditedFormula(): Formula {
        if (Visual.formulaEditMode === FormulaEditMode.None || !Visual.formulaEditPosition) {
            return undefined;
        }
        return this.findFormula(Visual.formulaEditPosition.hierarchyIdentity, Visual.formulaEditPosition.formula?.identity);
    }

    public allFormulasSkipped(): boolean {
        return this.formulaCalculation.formulas.every(f => this.skippedCategories.find(c => c === f.identity));
    }

    public findFormula(hierarchyIdentity: string, identity: string): Formula {
        return this.formulaCalculation.formulas.find(f => f.identity === identity && f.hierarchyIdentity === hierarchyIdentity);
    }

    public getCommentMarkersDataProperty(fullCategory: string, fullGroup: string): DataProperty {
        let key: CommentMarkerKey = {
            fullCategory: fullCategory,
            fullGroup: fullGroup ? fullGroup : ``
        };
        let dataProperty = this.commentMarkersDataProperties && this.commentMarkersDataProperties.get ?
            this.commentMarkersDataProperties.get(JSON.stringify(key)) : undefined;
        if (dataProperty !== undefined) {
            return dataProperty;
        }
        return null;
    }

    public setCommentMarkersDataProperty(fullCategory: string, fullGroup: string, dataProperty: DataProperty) {
        let key: CommentMarkerKey = {
            fullCategory: fullCategory,
            fullGroup: fullGroup ? fullGroup : ``
        };
        this.commentMarkersDataProperties.set(JSON.stringify(key), dataProperty);
        this.persistCommentMarkersDataProperties();
    }

    public shouldAddToExpressionElements(category: string, parentCategories: string, hierarchyIdentity: string) {
        let expressionMapping = this.formulaCalculation.expressionMappings.get(hierarchyIdentity);
        let expressionMap = expressionMapping?.get(`[${category}]`);
        let expressionelements = this.formulaCalculation.expressionElements.get(hierarchyIdentity);
        let parentCategoriesMap = expressionelements?.get(parentCategories);
        let element = parentCategoriesMap?.get(category);
        return expressionMap && !element;
    }

    public addExpressionElement(groupName: string, parentCategories: string, hierarchyIdentity: string, dataPoint: DataPoint) {
        let expressionElements = this.formulaCalculation.expressionElements.get(hierarchyIdentity);
        if (!expressionElements) {
            expressionElements = new Map<string, Map<string, Map<string, DataPoint>>>();
            this.formulaCalculation.expressionElements.set(hierarchyIdentity, expressionElements);
        }
        let groupMap = expressionElements.get(groupName);
        if (!groupMap) {
            groupMap = new Map<string, Map<string, DataPoint>>();
            expressionElements.set(groupName, groupMap);
        }
        let parentCategoryMap = groupMap.get(parentCategories);
        if (!parentCategoryMap) {
            parentCategoryMap = new Map<string, DataPoint>();
            groupMap.set(parentCategories, parentCategoryMap);
        }
        parentCategoryMap.set(`[${dataPoint.category}]`, dataPoint);
    }

    public changeColumnSettingAndPersist(dataProperty: DataProperty, propertyName: string, value: any) {
        this.changeColumnSetting(dataProperty, propertyName, value);
        this.persistColumnSettings();
    }

    private changeColumnSetting(dataProperty: DataProperty, propertyName: string, value: any) {
        let currentColumnSetting = this.getColumnSettings(dataProperty);
        currentColumnSetting[propertyName] = value;
    }

    public changeColumnViewSettingAndPersist(dataProperty: DataProperty, propertyName: string, value: any) {
        this.changeColumnViewSetting(dataProperty, propertyName, value);
        this.persistColumnSettings();
    }

    private changeColumnViewSetting(dataProperty: DataProperty, propertyName: string, value: any) {
        let currentColumnSetting = this.getColumnSettings(dataProperty);
        if (this.showAsTable) {
            currentColumnSetting.tableView[propertyName] = value;
        }
        else {
            currentColumnSetting.chartView[propertyName] = value;
        }
    }

    private persistColumnSettings() {
        let properties = { "columnSettings": stringifyMap(this.columnSettings) };
        this.columnSettingsString = stringifyMap(this.columnSettings);
        this.persistDesignProperty(properties);
        Visual.getInstance().constructViewModelAndUpdate(this);
    }

    private categoryFormatSettingExists(category: string) {
        let currentCategoryFormatSetting = this.getCategoryFormatSettings(category);
        return currentCategoryFormatSetting !== undefined;
    }

    private changeCategoryFormatSetting(category: string, propertyName: string, value: any) {
        if (!this.categoryFormatSettingExists(category)) {
            this.categoryFormatSettings.set(category, this.getEmptyDefaultCategoryFormatSettings())
        }
        this.getCategoryFormatSettings(category)[propertyName] = value;
    }

    public toggleAndPersistCategoryFormatBold(category: string) {
        let value = this.getCategoryFormatIsBold(category);
        this.changeCategoryFormatSettingAndPersist(category, "isBold", !value);
    }

    public toggleAndPersistCategoryFormatItalic(category: string) {
        let value = this.getCategoryFormatIsItalic(category);
        this.changeCategoryFormatSettingAndPersist(category, "isItalic", !value);
    }

    public toggleAndPersistCategoryFormatTopBorder(category: string) {
        let value = this.getCategoryFormatTopBorder(category);
        this.changeCategoryFormatSettingAndPersist(category, "topBorder", !value);
    }

    public persistCategoryFormatHighlightColor(category: string, color: any) {
        this.changeCategoryFormatSettingAndPersist(category, "highlightColor", color);
    }

    public persistCategoryFormatTextColor(category: string, color: any) {
        this.changeCategoryFormatSettingAndPersist(category, "textColor", color);
    }

    public persistCategoryFormatNumberFormat(category: string, numberFormat: DisplayUnits) {
        this.changeCategoryFormatSettingAndPersist(category, "numberFormat", numberFormat);
    }

    public persistCategoryFormatDecimalPlaces(category: string, decimalPlaces: number) {
        this.changeCategoryFormatSettingAndPersist(category, "decimalPlaces", decimalPlaces);
    }

    public changeCategoryFormatSettingAndPersist(category: string, propertyName: string, value: any) {
        this.changeCategoryFormatSetting(category, propertyName, value);
        this.persistCategoryFormatSettings();
    }

    private persistCategoryFormatSettings() {
        this.categoryFormatSettingsString = stringifyMap(this.categoryFormatSettings);
        Visual.getInstance().constructViewModelAndUpdate(this);
    }

    private persistCommentMarkersDataProperties() {
        this.commentMarkersDataPropertiesString = stringifyMap(this.commentMarkersDataProperties);
        Visual.getInstance().constructViewModelAndUpdate(this);
    }

    public persistCommentBoxSize(size: string) {
        this.commentBoxSize = size;
        Visual.getInstance().constructViewModelAndUpdate(this);
    }

    public persistCommentBoxTitle(commentBoxTitle: CommentBoxTitle) {
        this.commentBoxTitle = commentBoxTitle;
        Visual.getInstance().constructViewModelAndUpdate(this);
    }

    public persistCommentBoxVariance(commentBoxVariance: CommentBoxVariance) {
        this.commentBoxShowVariance = commentBoxVariance;
        Visual.getInstance().constructViewModelAndUpdate(this);
    }

    public persistCommentBoxVarianceIcon(commentBoxVarianceIcon: VarianceIcon) {
        this.commentBoxVarianceIcon = commentBoxVarianceIcon;
        Visual.getInstance().constructViewModelAndUpdate(this);
    }

    public persistCommentBoxSettings() {
        Visual.getInstance().constructViewModelAndUpdate(this);
    }

    public persistTitleStyle(text: string, fontColor: string, fontFamily: string, fontSize: number, fontWeight: string, fontStyle: string, alignment: string, wrapLongTitle: boolean) {
        this.titleFontFamily = fontFamily;
        this.titleFontSize = fontSize;
        this.titleText = text;
        this.titleFontColor = fontColor;
        this.titleAlignment = alignment;
        this.titleWrap = wrapLongTitle;
        this.titleFontWeight = fontWeight;
        this.titleFontStyle = fontStyle;
        Visual.getInstance().constructViewModelAndUpdate(this);
    }

    public isCommentBoxVertical() {
        return this.commentBoxPlacement === CommentBoxPlacement.Left || this.commentBoxPlacement === CommentBoxPlacement.Right;
    }

    public getColumnSettings(dataProperty: DataProperty): ColumnSettings {
        let columnName = this.getColumnName(dataProperty);
        return this.columnSettings.get(columnName);
    }

    public getCategoryFormatSettings(category: string): CategoryFormatSettings {
        return this.categoryFormatSettings.get(category);
    }

    public getColumnName(dataProperty: DataProperty): string {
        switch (dataProperty) {
            case DataProperty.Value:
                return this.getNameFromScenario(this.viewModel.value.scenario);
            case DataProperty.ReferenceValue:
                return this.getNameFromScenario(this.viewModel.reference.scenario);
            case DataProperty.SecondReferenceValue:
                return this.getNameFromScenario(this.viewModel.secondReference.scenario);
            case DataProperty.ThirdReferenceValue:
                return this.getNameFromScenario(this.viewModel.thirdReference.scenario);
            case DataProperty.FourthReferenceValue:
                return this.getNameFromScenario(this.viewModel.fourthReference.scenario);
            case DataProperty.FifthReferenceValue:
                return this.getNameFromScenario(this.viewModel.fifthReference.scenario);
            case DataProperty.SixthReferenceValue:
                return this.getNameFromScenario(this.viewModel.sixthReference.scenario);
            case DataProperty.SeventhReferenceValue:
                return this.getNameFromScenario(this.viewModel.seventhReference.scenario);
            case DataProperty.AbsoluteDifference:
            case DataProperty.RelativeDifference:
                return this.getVarianceColumnName(this.valueScenario, this.referenceScenario, dataProperty);
            case DataProperty.SecondAbsoluteDifference:
            case DataProperty.SecondRelativeDifference:
                return this.getVarianceColumnName(this.secondValueScenario, this.secondReferenceScenario, dataProperty);
            case DataProperty.ThirdAbsoluteDifference:
            case DataProperty.ThirdRelativeDifference:
                return this.getVarianceColumnName(this.thirdValueScenario, this.thirdReferenceScenario, dataProperty);
            case DataProperty.FourthAbsoluteDifference:
            case DataProperty.FourthRelativeDifference:
                return this.getVarianceColumnName(this.fourthValueScenario, this.fourthReferenceScenario, dataProperty);
            case DataProperty.FifthAbsoluteDifference:
            case DataProperty.FifthRelativeDifference:
                return this.getVarianceColumnName(this.fifthValueScenario, this.fifthReferenceScenario, dataProperty);
            case DataProperty.SixthAbsoluteDifference:
            case DataProperty.SixthRelativeDifference:
                return this.getVarianceColumnName(this.sixthValueScenario, this.sixthReferenceScenario, dataProperty);
            case DataProperty.SeventhAbsoluteDifference:
            case DataProperty.SeventhRelativeDifference:
                return this.getVarianceColumnName(this.seventhValueScenario, this.seventhReferenceScenario, dataProperty);
            case DataProperty.AdditionalMeasure1:
            case DataProperty.AdditionalMeasure2:
            case DataProperty.AdditionalMeasure3:
            case DataProperty.AdditionalMeasure4:
            case DataProperty.AdditionalMeasure5:
            case DataProperty.AdditionalMeasure6:
            case DataProperty.AdditionalMeasure7:
            case DataProperty.AdditionalMeasure8:
            case DataProperty.AdditionalMeasure9:
            case DataProperty.AdditionalMeasure10:
            case DataProperty.AdditionalMeasure11:
            case DataProperty.AdditionalMeasure12:
            case DataProperty.AdditionalMeasure13:
            case DataProperty.AdditionalMeasure14:
            case DataProperty.AdditionalMeasure15:
            case DataProperty.AdditionalMeasure16:
            case DataProperty.AdditionalMeasure17:
            case DataProperty.AdditionalMeasure18:
            case DataProperty.AdditionalMeasure19:
            case DataProperty.AdditionalMeasure20:
                return this.viewModel.additionalMeasures[helpersVisual.getIndexFromAdditionalMeasureDataProperty(dataProperty)]?.fieldName;
        }
    }

    public getDataPropertyFromColumnSettings(target: ColumnSettings): DataProperty {
        for (let dataProperty = 0; dataProperty < NumberOfDataProperties; dataProperty++) {
            let currentColumn = this.columnSettings.get(this.getColumnName(dataProperty));
            if (currentColumn === target) {
                return dataProperty;
            }
        }
        return null;
    }

    public getDataPropertyFromColumnName(columnName: string): DataProperty {
        let settings = this.columnSettings.get(columnName);
        if (!settings) {
            return DataProperty.Value;
        }
        return this.getDataPropertyFromColumnSettings(settings);
    }

    public getVarianceColumnName(valueScenario: Scenario, referenceScenario: Scenario, dataProperty: DataProperty): string {
        let valueName = this.getNameFromScenario(valueScenario);
        let referenceName = this.getNameFromScenario(referenceScenario);
        return `${valueName}-${referenceName}` + (helpersVisual.isRelativeMeasure(dataProperty, this) ? "-percent" : EMPTY);
    }

    public getNameFromScenario(scenario: Scenario): string {
        switch (scenario) {
            case Scenario.Actual:
                return ACTUAL;
            case Scenario.PreviousYear:
                return PREVIOUS_YEAR;
            case Scenario.Forecast:
                return FORECAST;
            case Scenario.Forecast2:
                return FORECAST2;
            case Scenario.Forecast3:
                return FORECAST3;
            case Scenario.Plan:
                return PLAN;
            case Scenario.Plan2:
                return PLAN2;
            case Scenario.Plan3:
                return PLAN3;
        }
    }

    public getScenarioFromName(name: string): Scenario {
        switch (name) {
            case ACTUAL:
                return Scenario.Actual;
            case PREVIOUS_YEAR:
                return Scenario.PreviousYear;
            case FORECAST:
                return Scenario.Forecast;
            case FORECAST2:
                return Scenario.Forecast2;
            case FORECAST3:
                return Scenario.Forecast3;
            case PLAN:
                return Scenario.Plan;
            case PLAN2:
                return Scenario.Plan2;
            case PLAN3:
                return Scenario.Plan3;
        }
    }

    public getPositionIndexFromCalculationName(str: string): number {
        if (str.includes("2")) {
            return 1;
        } else if (str.endsWith("3")) {
            return 2;
        } else {
            return 0;
        }
    }

    private getColumnSetting(dataProperty: DataProperty, propertyName: string): any {
        let settings = this.getColumnSettings(dataProperty);
        return settings?.[propertyName];
    }

    public getColumnViewSetting(dataProperty: DataProperty, propertyName: string): any {
        let settings = this.getColumnSettings(dataProperty);
        if (this.showAsTable) {
            return settings?.tableView[propertyName];
        }
        else {
            return settings?.chartView[propertyName];
        }
    }

    public getColumnViewSettingsByColumnName(name: string, propertyName: string): any {
        let settings = this.columnSettings.get(name);
        if (!settings) {
            return null;
        }
        if (this.showAsTable) {
            return settings.tableView[propertyName];
        }
        else {
            return settings.chartView[propertyName];
        }
    }

    public getColumnCategoryTextLabel(name: string, propertyName: string): any {
        let settings = this.columnSettings.get(name);
        if (!settings) {
            return null;
        }
        if (this.showAsTable) {
            return settings.tableView[propertyName];
        }
        else {
            return settings.chartView[propertyName];
        }
    }

    public setSortColumn() {
        if (this.sortColumnName) {
            return;
        }
        // Here we set the default sort columns in case the users has not selected any yet.
        if (this.viewModel.HasValueColumn) {
            this.sortColumnName = this.getNameFromScenario(this.viewModel.value.scenario);
        }
        else if (this.viewModel.additionalMeasures.length > 0) {
            this.sortColumnName = this.getColumnName(DataProperty.AdditionalMeasure1);
        }
    }

    public getCategoryFormatSetting(categoryName: string, propertyName: string): any {
        let settings = this.getCategoryFormatSettings(categoryName);
        return settings !== undefined ? settings[propertyName] : null;
    }

    public setOrganizationStyleSettings(organizationStyleData: OrganizationStyleData) {
        // settings.colorScheme = { ...settings.colorScheme, ...importedSettings.colorScheme }; //possibly use this if we'd want to merge the color schemes
        this.chartStyle = ChartStyle.Company;
        this.selectedOrganizationStyleId = organizationStyleData.id;
        for (const [key, value] of Object.entries(organizationStyleData.styleJSON)) {
            const settingName = key.split("_").join("-");
            this[settingName] = value;
        }
    }
}
